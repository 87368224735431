import React, {useEffect, useRef, useState} from 'react';
import Transition from '../utils/Transition.js';
import IC6Logo from '../images/Logo/IC6_Blue.png';
import IC7Logo from '../images/Logo/ic7_orange.png';
import IC7PlusLogo from '../images/Logo/ic7_plus_orange.png';
import IOTPink from '../images/Logo/IoT.png';
import Lottie from 'react-lottie';
import bluetoothData from '../lottie/867-bluetooth.json';
import cellularData from '../lottie/lf30_editor_opmodje1.json';
import animationData1 from '../lottie/lf30_editor_wqq7lwyz.json';
import mapDataIc6 from '../lottie/lf30_editor_tgdqlsrm.json';
import mapDataIc7 from '../lottie/lf30_editor_uka5qjqe.json';
import {Link} from "react-router-dom";
import useWindowDimensions from "../utils/GetScreenDimensions";

function SolutionsTabs() {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    let width_IC7Plus = '49%'
    let {width} = useWindowDimensions()
    if (width >= 600) {
        width_IC7Plus = "25%"
    }

    const wrapperFunction_tab1 = () => {
        setTab(1)
        goToTop()
    }
    const wrapperFunction_tab2 = () => {
        setTab(2)
        goToTop()
    }
    const wrapperFunction_tab3 = () => {
        setTab(3)
        goToTop()
    }
    const wrapperFunction_tab4 = () => {
        setTab(4)
        goToTop()
    }

    const [tab, setTab] = useState(1);

    const tabs = useRef(null);

    const heightFix = () => {
        if (tabs.current.children[tab - 1]) {
            tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
        }
    }
    const defaultOptionsMapDataIC6 = {
        loop: true,
        autoplay: true,
        animationData: mapDataIc6,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionsMapDataIC7 = {
        loop: true,
        autoplay: true,
        animationData: mapDataIc7,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionBluetooth = {
        loop: true,
        autoplay: true,
        animationData: bluetoothData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionCellular = {
        loop: true,
        autoplay: true,
        animationData: cellularData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionsAnimation = {
        loop: true,
        autoplay: true,
        animationData: animationData1,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        heightFix()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    return (
        <section>
            <div className="mx-auto px-0 sm:px-0 bg-gray-900">
                <div className="mx-auto pt-20 sm:px-0 md:pt-20 ">

                    {/* Section header */}
                    <div className="sm:max-w-4xl max-w-full mx-auto text-center sm:pb-6 pb-2 sm:pt-10 pt-2"
                         data-aos-id-tabs>

                        <h2 className="h1 sm:mb-4 text-white tracking-wide" data-aos="fade-up"
                            data-aos-anchor="[data-aos-id-tabs]">TELEMATICS</h2>
                        <p className="sm:text-xl text-lg text-white" data-aos="fade-up" data-aos-delay="200"
                           data-aos-anchor="[data-aos-id-tabs]">Introducing the IC device range, fully white labelled
                            telematics solutions. Combining cutting-edge hardware design, patented software and
                            low-speed collision detection.</p>
                    </div>

                    {/* Tabscontent */}
                    <div>
                        {/* SolutionsTabs buttons */}
                        <div className="max-w-6xl mx-auto pb-4 border-b border-gray-400">
                            <div
                                className="grid md:grid-cols-4 grid-cols-4 items-center justify-center m-0"
                                data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-gray-100 ${tab === 1 ? 'bg-gray-900 border-4 border-blue-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={() => setTab(1)}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight tracking-tighter mx-1 ${tab === 1 ? 'text-blue-500' : 'text-blue-500'}`}>IC6</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 hover:bg-gray-100 ${tab === 2 ? 'bg-gray-900 border-4 border-yellow-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={() => setTab(2)}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight tracking-tighter mx-1 ${tab === 2 ? 'text-yellow-500' : 'text-yellow-500'}`}>IC7</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 hover:bg-gray-100 ${tab === 3 ? 'bg-gray-900 border-4 border-yellow-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={() => setTab(3)}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight tracking-tighter mx-1 ${tab === 3 ? 'text-yellow-500' : 'text-yellow-500'}`}>IC7+</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 hover:bg-gray-100 ${tab === 4 ? 'bg-gray-900 border-4 border-pink-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={() => setTab(4)}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight tracking-tighter mx-1 ${tab === 4 ? 'text-pink-500' : 'text-pink-500'}`}>IoT</h3>
                                </button>

                            </div>
                        </div>

                        {/* SolutionsTabs items */}
                        <div className="bg-gray-900">
                            <div className="max-w-6xl mx-auto relative flex flex-col py-4" data-aos="fade-up"
                                 ref={tabs}>

                                {/* Item 1 */}
                                <Transition
                                    show={tab === 1}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-500 transform order-first"
                                    enterStart="opacity-0 scale-98"
                                    enterEnd="opacity-100 scale-100"
                                    leave="transition ease-out duration-300 transform absolute"
                                    leaveStart="opacity-100 scale-100"
                                    leaveEnd="opacity-0 scale-98"
                                >
                                    <div className="pb-3 md:pb-2">

                                        {/* Section header */}
                                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-4">
                                            <img className="object-contain object-left w-40 h-40 inline-block"
                                                 src={IC6Logo}
                                                 alt="fireSpot"/>
                                            <h2 className="h3 text-gray-100" data-aos="fade-up">A low cost,
                                                self-install, usage-based device.</h2>
                                        </div>

                                        {/* Items */}
                                        <div className="grid gap-20" data-aos-id-target>

                                            {/* Item */}
                                            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

                                                {/* Image */}
                                                <figure
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:py-6 md:rtl mt-8"
                                                    data-aos="fade-right" data-aos-delay="200"
                                                    data-aos-anchor="[data-aos-id-target]">
                                                    <div className="relative inset-0 z-20 block">
                                                        <video
                                                            autoPlay
                                                            muted
                                                            loop
                                                            playsInline
                                                            style={{height: "100%", width: "100%", objectFit: "cover"}}
                                                        >
                                                            <source
                                                                src="https://agmtwebsitevideo.s3.eu-west-1.amazonaws.com/IC6Square.mp4"
                                                                type="video/mp4"/>

                                                        </video>
                                                    </div>
                                                </figure>

                                                {/* Content */}
                                                <div
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 px-2 sm:px-0">
                                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                                        <div className="mt-6 md:mt-0" data-aos="fade-left"
                                                             data-aos-delay="200"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">Historically, the
                                                                adoption
                                                                of usage-based insurance (UBI) has been limited by a
                                                                combination of the high cost involved in installing
                                                                hardware
                                                                and the inability to accurately record data.
                                                            </p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">The IC6
                                                                has
                                                                a simple peel and stick installation keeping costs to a
                                                                minimum.
                                                                Powered by an internal battery,
                                                                the device requires no external wiring.</p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">An inbuilt
                                                                accelerometer and GPS enable the device to
                                                                accurately record driver behaviour, journey data &
                                                                collisions,
                                                                with transmissions via Bluetooth Low Energy tethered
                                                                to a smartphone.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </Transition>

                                {/* Item 2 */}
                                <Transition
                                    show={tab === 2}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-500 transform order-first"
                                    enterStart="opacity-0 scale-98"
                                    enterEnd="opacity-100 scale-100"
                                    leave="transition ease-out duration-300 transform absolute"
                                    leaveStart="opacity-100 scale-100"
                                    leaveEnd="opacity-0 scale-98"
                                >
                                    <div className="pb-3 md:pb-2">

                                        {/* Section header */}
                                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-4">
                                            <img className="object-contain object-left w-40 h-40 inline-block"
                                                 src={IC7Logo}
                                                 alt="fireSpot"/>
                                            <h2 className="h3 text-gray-100" data-aos="fade-up"> A low cost,
                                                self-install,
                                                long life device.</h2>
                                        </div>

                                        {/* Items */}
                                        <div className="grid gap-20" data-aos-id-target>

                                            {/* Item */}
                                            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

                                                {/* Image */}
                                                <figure
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:py-6 md:rtl mt-8"
                                                    data-aos="fade-right" data-aos-delay="200"
                                                    data-aos-anchor="[data-aos-id-target]">
                                                    <div className="relative">
                                                        <div
                                                            className="md:left-16 md:-top-0 left-16 lg:left-24 lg:top-8 transform rotate-90 absolute">
                                                            <Lottie options={defaultOptionsAnimation} height={150}
                                                                    width={150}/>
                                                        </div>
                                                        <div className="absolute -top-12 -right-12">
                                                            <Lottie options={defaultOptionBluetooth} height={200}
                                                                    width={200}/>
                                                        </div>
                                                        <img className="w-full h-full object-cover"
                                                             src={require('../images/Dash.png').default} width="516"
                                                             height="387" alt="SolutionsTabs 01"/>
                                                    </div>
                                                </figure>

                                                {/* Content */}
                                                <div
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 px-2 sm:px-2">
                                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="200"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">The IC7 is a
                                                                self-standing,
                                                                data capturing solution with an inbuilt 3-axis
                                                                accelerometer and GPS. The low-cost unit
                                                                connects via BLE to a mobile device to transmit data to
                                                                the
                                                                cloud. An internal backup battery supports solar
                                                                harvesting cells which results in a <span
                                                                    className="text-yellow-500">3–5 year</span> device
                                                                life.
                                                            </p>
                                                        </div>
                                                        <div className="mt-6 md:mt-12" data-aos="fade-left"
                                                             data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">Suitable for a wide
                                                                variety
                                                                of markets, the IC7 is suitable for a variety of
                                                                markets,
                                                                including: insurance, fleet management, asset tracking
                                                                and
                                                                many more.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>

                                <Transition
                                    show={tab === 3}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-500 transform order-first"
                                    enterStart="opacity-0 scale-98"
                                    enterEnd="opacity-100 scale-100"
                                    leave="transition ease-out duration-300 transform absolute"
                                    leaveStart="opacity-100 scale-100"
                                    leaveEnd="opacity-0 scale-98"
                                >
                                    <div className="pb-3 md:pb-2">

                                        {/* Section header */}
                                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-4 pt-8">
                                            <img className="object-contain object-left inline-block"
                                                 src={IC7PlusLogo}
                                                 alt="fireSpot"
                                                 width={width_IC7Plus}/>
                                            <h2 className="h3 text-gray-100 pt-3" data-aos="fade-up"> A stand-alone
                                                3-year
                                                analytics device.</h2>
                                        </div>

                                        {/* Items */}
                                        <div className="grid gap-20" data-aos-id-target>

                                            {/* Item */}
                                            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

                                                {/* Image */}
                                                <figure
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:py-4 md:rtl mt-8"
                                                    data-aos="fade-right" data-aos-delay="200"
                                                    data-aos-anchor="[data-aos-id-target]">
                                                    <div className="relative">
                                                        <div
                                                            className="md:left-16 md:-top-0 left-16 lg:left-24 lg:top-8 transform rotate-90 absolute">
                                                            <Lottie options={defaultOptionsAnimation} height={150}
                                                                    width={150}/>
                                                        </div>
                                                        <div className="absolute -top-12 -right-12">
                                                            <Lottie options={defaultOptionBluetooth} height={200}
                                                                    width={200}/>
                                                        </div>
                                                        <div className="absolute -top-2 right-20">
                                                            <Lottie options={defaultOptionCellular} height={100}
                                                                    width={100}/>
                                                        </div>
                                                        <img className="w-full h-full object-cover"
                                                             src={require('../images/Dash.png').default} width="516"
                                                             height="387" alt="SolutionsTabs 01"/>
                                                    </div>
                                                </figure>

                                                {/* Content */}
                                                <div
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 sm:px-0 px-2">
                                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="200"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">Incorporating all the
                                                                features from the IC7 with the addition of an integrated
                                                                modem, the
                                                                IC7+ is truly stand-alone. Combining solar harvesting
                                                                with
                                                                cellular connection enables the device to operate
                                                                autonomously with
                                                                no further support once mounted.

                                                            </p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">Data is uploaded to the
                                                                cloud in near real time following simple,
                                                                self-installation.
                                                            </p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-gray-100">Optional extras
                                                                include LoRa communication, 9 axis accelerometer/gyro,
                                                                additional sensors and much more.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>

                                <Transition
                                    show={tab === 4}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-500 transform order-first"
                                    enterStart="opacity-0 scale-98"
                                    enterEnd="opacity-100 scale-100"
                                    leave="transition ease-out duration-300 transform absolute"
                                    leaveStart="opacity-100 scale-100"
                                    leaveEnd="opacity-0 scale-98"
                                >
                                    <div className="pb-3 md:pb-2">

                                        {/* Section header */}
                                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-4">
                                            <img className="object-contain object-left h-40 w-40 inline-block"
                                                 src={IOTPink}
                                                 alt="fireSpot"/>
                                            <h2 className="h3 text-gray-100" data-aos="fade-up"> Specialist team
                                                creating bespoke solutions.</h2>
                                        </div>

                                        {/* Items */}
                                        <div className="grid gap-20" data-aos-id-target>

                                            {/* Item */}
                                            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">

                                                {/* Image */}


                                                {/* Content */}
                                                <div
                                                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12 sm:px-0 px-2 pb-4">
                                                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="200"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-center text-gray-100 md:text-left">Refined
                                                                over many
                                                                years,
                                                                our team have the experience and proficiency to produce
                                                                exclusive innovations. Whether the requirement be
                                                                software,
                                                                hardware or a combination, AGMT is expertly equipped to
                                                                handle your request.

                                                            </p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">
                                                            <p className="text-xl text-center text-gray-100 md:text-left">Our
                                                                cutting-edge design
                                                                team are on hand to rejuvenate your current offering or
                                                                provide you with something new. With support from our
                                                                backend developers, we can provide full end to end
                                                                solutions.</p>
                                                        </div>
                                                        <div className="mt-6" data-aos="fade-left" data-aos-delay="400"
                                                             data-aos-anchor="[data-aos-id-target]">

                                                            <div className="max-w-7xl mx-auto">
                                                                {/* Items */}
                                                                <div
                                                                    className="max-w-sm mx-auto grid gap-8 md:grid-cols-1 lg:grid-cols-2 lg:gap-16 items-start md:max-w-2xl lg:max-w-max"
                                                                    data-aos-id-blocks>

                                                                    {/* 1st item */}
                                                                    <div
                                                                        className="relative flex flex-col items-center border border-gray-200 rounded px-2 sm:px-6 py-6"
                                                                        data-aos="fade-up"
                                                                        data-aos-anchor="[data-aos-id-blocks]">
                                                                        <h4 className="h4 mb-2 text-gray-100">Low-Power
                                                                            Asset Tracking</h4>
                                                                        <div
                                                                            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-8 mb-2 md:mb-0 md:rtl pt-4"
                                                                            data-aos="fade-up">
                                                                            <img
                                                                                className="max-w-full mx-auto md:max-w-none h-auto transform hover:scale-105 transition duration-700 ease-out"
                                                                                src={require('../images/venti-views-1cqIcrWFQBI-unsplash Large.jpg').default}
                                                                                width="100%"
                                                                                height="50%" alt="Features 02"/>
                                                                        </div>
                                                                        <li className="flex items-center mb-2 text-gray-100 pt-8">
                                                                            <div
                                                                                className='text-gray-100 text-lg text-center md:text-left'>
                                                                            <span> As our hardware products require no external wiring/cabling, they are an extremely attractive solution for tracking assets with power limitations. All our devices have configurable GPS frequency reporting, enabling the battery power to be extended as much as possible. Combined with our ultra-low power data transmission algorithm, we can provide accurate tracking solutions for even the most power-limited cases.
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    </div>

                                                                    <div
                                                                        className="relative flex flex-col items-center border border-gray-200 rounded px-2 sm:px-6 py-6"
                                                                        data-aos="fade-up"
                                                                        data-aos-anchor="[data-aos-id-blocks]">
                                                                        <Link
                                                                            className="h4 mb-2 text-gray-100 text-center"
                                                                            href="/crash-detection"> Low-Speed
                                                                            Collision Detection</Link>
                                                                        <div
                                                                            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-8 mb-2 md:mb-0 md:rtl pt-4"
                                                                            data-aos="fade-up">
                                                                            <img
                                                                                className="max-w-full mx-auto md:max-w-none h-auto transform hover:scale-105 transition duration-700 ease-out"
                                                                                src={require('../images/JoeCrashScrape.jpg').default}
                                                                                width="100%"
                                                                                height="50%" alt="Features 02"/>
                                                                        </div>
                                                                        <li className="flex items-center mb-2 text-gray-100 pt-8">
                                                                            <div
                                                                                className='text-gray-100 text-lg text-center md:text-left'>
                                                                                <span> Through our partnership with the European Space Agency, we have developed revolutionary collision detection for the insurance industry. Our ability to accurately record, analyse and determine the cause of low power accelerometer data has resulted in huge savings for motor insurance providers. The IC6 and IC7 can accurately evaluate a policyholder’s risk and provide an insurer with the data to make an informed decision.</span>
                                                                            </div>
                                                                        </li>
                                                                    </div>

                                                                    <div
                                                                        className="relative flex flex-col items-center border border-gray-200 rounded px-2 sm:px-6 pt-6 pb-12"
                                                                        data-aos="fade-up"
                                                                        data-aos-anchor="[data-aos-id-blocks]">
                                                                        <h4 className="h4 mb-2 text-gray-100">Idling
                                                                            Efficiency</h4>
                                                                        <div
                                                                            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-8 mb-2 md:mb-0 md:rtl pt-4"
                                                                            data-aos="fade-up">
                                                                            <img
                                                                                className="max-w-full mx-auto md:max-w-none h-auto transform hover:scale-105 transition duration-700 ease-out"
                                                                                src={require('../images/shane-mclendon-9jPJrfLTBi0-unsplash Large.jpg').default}
                                                                                width="100%"
                                                                                height="50%" alt="Features 02"/>
                                                                        </div>
                                                                        <li className="flex items-center mb-2 text-gray-100 pt-8">
                                                                            <div
                                                                                className='text-gray-100 text-lg text-center md:text-left'>
                                                                                <span> Our devices’ heightened sensitivity enables them to accurately monitor plant and machinery usage and empower companies to make huge operational cost savings. The granular data captured and evaluated through our IC7+ device helps track idling amongst fleets with GPS and cellular connectivity doubling as a real-time tracking solution.</span>
                                                                            </div>
                                                                        </li>
                                                                    </div>

                                                                    <div
                                                                        className="relative flex flex-col items-center border border-gray-200 rounded px-2 sm:px-6 py-6"
                                                                        data-aos="fade-up"
                                                                        data-aos-anchor="[data-aos-id-blocks]">
                                                                        <h4 className="h4 mb-2 text-gray-100">Pipeline
                                                                            Monitoring</h4>
                                                                        <div
                                                                            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-8 mb-2 md:mb-0 md:rtl pt-4"
                                                                            data-aos="fade-up">
                                                                            <img
                                                                                className="max-w-full mx-auto md:max-w-none h-auto transform hover:scale-105 transition duration-700 ease-out"
                                                                                src={require('../images/quinten-de-graaf-L4gN0aeaPY4-unsplash Large.jpg').default}
                                                                                width="100%"
                                                                                height="50%" alt="Features 02"/>
                                                                        </div>
                                                                        <li className="flex items-center mb-2 text-gray-100 pt-8">
                                                                            <div
                                                                                className='text-gray-100 text-lg text-center md:text-left'>
                                                                                <span> Combining our low-speed accelerometer detection capability with low-power battery management created a device capable of monitoring pipeline activity. The device captures the pipeline data and helps build a model to determine the meaning of the different types of accelerometer data. These profiles are then fed back to the device and to improve its accuracy and evaluation through machine learning. </span>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`bg-gray-900 ${tab === 4 && 'bg-pink-500' && 'h-px'}`}>
                <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8 md:py-12 border-t border-gray-300" ref={tabs}>
                    <div className="py-8 md:py-8">
                        <Transition
                            show={tab === 1}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h2 className="h2 mb-4 text-center pb-8 text-gray-100" data-aos="fade-up">Features</h2>
                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none">
                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up">
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/money.png').default} alt="money icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Low Cost</h4>
                                    <p className="text-xl text-gray-100 text-center">Suitable for all drivers in all
                                        vehicles.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="100"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/tools.png').default} alt="tools icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Self-install</h4>
                                    <p className="text-xl text-gray-100 text-center">NO appointment, NO engineer, NO
                                        installation costs.</p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/brand.png').default} alt="brand icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Fully White Labelled</h4>
                                    <p className="text-xl text-gray-100 text-center">Full customer branding on both
                                        sides of
                                        the device.</p>
                                </div>

                                {/* 4th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="300"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/car.png').default} alt="car icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Driver Behaviour</h4>
                                    <p className="text-xl text-gray-100 text-center">Accelerometer generated
                                        accelerating,
                                        braking and cornering events & speeding events, with <span
                                            className="text-red-500 underline" href="/crash-detection">collision detection</span>.
                                    </p>
                                </div>

                                {/* 5th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/link.png').default} alt="link">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Local Storage</h4>
                                    <p className="text-xl text-gray-100 text-center">Up to 3 months’ worth of data held
                                        locally.</p>
                                </div>

                                {/* 6th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="500"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/score.png').default} alt="score icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Driver Scoring</h4>
                                    <p className="text-xl text-gray-100 text-center">Evaluate driver performance by
                                        utilising our customisable performance algorithm.</p>
                                </div>

                            </div>
                        </Transition>
                        <Transition
                            show={tab === 2}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h2 className="h2 mb-4 text-center pb-8 text-gray-100" data-aos="fade-up">Features</h2>
                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none">

                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/money_orange.png').default} alt="money icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Low Cost</h4>
                                    <p className="text-xl text-gray-100 text-center">Mass suitability.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="100"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/battery.png').default} alt="battery icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Long Life</h4>
                                    <p className="text-xl text-gray-100 text-center">3-5 year lifespan.
                                    </p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/priority.png').default} alt="priority icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Edge Processing</h4>
                                    <p className="text-xl text-gray-100 text-center">Patented on-device intelligence
                                        maximises efficiency.</p>
                                </div>

                                {/* 4th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="300"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/tools_orange.png').default} alt="tools icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Self-Install</h4>
                                    <p className="text-xl text-gray-100 text-center">NO appointment, NO engineer, NO
                                        installation costs.</p>
                                </div>

                                {/* 5th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/Solar.png').default} alt="solar icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Solar Harvesting</h4>
                                    <p className="text-xl text-gray-100 text-center">Primary power through solar
                                        harvesting.</p>
                                </div>

                                {/* 6th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="500"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/car_orange.png').default} alt="car icon orange">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Driver Behaviour</h4>
                                    <p className="text-xl text-gray-100 text-center">Accelerometer generated
                                        accelerating,
                                        braking and cornering events & speeding events, with <span
                                            className="text-red-500 underline">collision detection</span>.</p>
                                </div>

                            </div>
                        </Transition>
                        <Transition
                            show={tab === 3}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h2 className="h2 mb-4 text-center pb-8 text-gray-100" data-aos="fade-up">Features</h2>
                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none">

                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/Solar.png').default} alt="Solar icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Solar Harvesting</h4>
                                    <p className="text-xl text-gray-100 text-center">Primary power through solar
                                        harvesting.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="100"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/broadcast.png').default} alt="cellular icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Cellular</h4>
                                    <p className="text-xl text-gray-100 text-center">Onboard cellular module
                                        enables direct uplink to data services.</p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/priority.png').default} alt="priority icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">FNOL</h4>
                                    <p className="text-xl text-gray-100 text-center">Real-time incident
                                        notifications.</p>
                                </div>

                                {/* 4th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="300"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/tools_orange.png').default} alt="tool orange">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Self-Install</h4>
                                    <p className="text-xl text-gray-100 text-center">NO appointment, NO engineer, NO
                                        installation costs.</p>
                                </div>

                                {/* 5th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/steering-wheel_orange.png').default}
                                         alt="steering wheel orange">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Analytics</h4>
                                    <p className="text-xl text-gray-100 text-center">Enhanced sensors, gyro and
                                        communications enable details data capture.</p>
                                </div>

                                {/* 6th item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="500"
                                >
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/car_orange.png').default} alt="car icon orange">
                                    </img>
                                    <h4 className="h4 mb-2 text-white">Standalone</h4>
                                    <p className="text-xl text-gray-100 text-center">Solar rechargeable integrated power
                                        source and modem.</p>
                                </div>

                            </div>
                        </Transition>

                        <Transition
                            show={tab === 4}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                        </Transition>
                    </div>
                </div>
            </div>

            <div className={`bg-gray-900 ${tab === 4 && 'bg-pink-500' && 'h-px'}`}>
                <div
                    className={`max-w-6xl mx-auto px-4 sm:px-6 border-t order-gray-300 ${tab === 4 && 'bg-pink-500' && 'h-px'}`}
                    ref={tabs}>
                    <div className="py-8 md:py-8">
                        <Transition
                            show={tab === 1}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <div className="max-w-3xl mx-auto text-center pb-2 md:pb-2">
                                <h2 className="h2 mb-4 pt-8 text-gray-100" data-aos="fade-up">Process flow</h2>
                                <p className="text-xl text-gray-100" data-aos="fade-up" data-aos-delay="200">Capturing
                                    driver data has never been easier.</p>
                                <div className="relative transform rotate-90">
                                    <Lottie options={defaultOptionsMapDataIC6} height={300} width={300}/>
                                </div>
                            </div>
                            {/* Items */}

                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">

                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="200"/>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/pairing.png').default} alt="Pairing Icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">1</span>. Pair
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Users receive their device and
                                        follow
                                        simple installations instructions via their mobile.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="400"/>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/steering-wheel.png').default}
                                         alt="Steering Wheel icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">2</span>.
                                        Drive
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Once paired, the device captures
                                        all
                                        data autonomously.</p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400">
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/analyse.png').default} alt="Analyse icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">3</span>.
                                        Analyse
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Driving insights and raw data are
                                        available through our customisable platform integrations.</p>
                                </div>
                            </div>
                        </Transition>
                        <Transition
                            show={tab === 2}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <div className="max-w-3xl mx-auto text-center pb-2 md:pb-2">
                                <h2 className="h2 mb-4 text-gray-100" data-aos="fade-up">Process flow</h2>
                                <p className="text-xl text-gray-100" data-aos="fade-up" data-aos-delay="200">Capturing
                                    driving data has never been easier.</p>
                                <div className="relative transform rotate-90">
                                    <Lottie options={defaultOptionsMapDataIC7} height={300} width={300}/>
                                </div>
                            </div>
                            {/* Items */}

                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">

                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="200"></div>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/tools_orange.png').default} alt="Tools orange">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">1</span>.
                                        Install
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Follow simple self-installation
                                        instructions.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="400"></div>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/steering-wheel_orange.png').default}
                                         alt={"Steering wheel orange"}>
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">2</span>.
                                        Capture
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">The device will observe and capture
                                        data autonomously.</p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400">
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/analyse_orange.png').default} alt="Analyse tool">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">3</span>.
                                        Analyse
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Data is available in near real time
                                        and
                                        is made readily available through our many customisable platforms.</p>
                                </div>
                            </div>
                        </Transition>
                        <Transition
                            show={tab === 3}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <div className="max-w-3xl mx-auto text-center pb-2 md:pb-2">
                                <h2 className="h2 mb-4 text-gray-100" data-aos="fade-up">Process flow</h2>
                                <p className="text-xl text-gray-200" data-aos="fade-up" data-aos-delay="200">Capturing
                                    driving data has never been easier.</p>
                                <div className="relative transform rotate-90">
                                    <Lottie options={defaultOptionsMapDataIC7} height={300} width={300}/>
                                </div>
                            </div>
                            {/* Items */}

                            <div
                                className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">

                                {/* 1st item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="200"></div>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/tools_orange.png').default} alt="Icon tools">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">1</span>.
                                        Install
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Follow simple self-installation
                                        instructions.</p>
                                </div>

                                {/* 2nd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="200">
                                    <div aria-hidden="true"
                                         className="absolute h-1 border-t border-dashed border-gray-100 hidden md:block"
                                         style={{width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px'}}
                                         data-aos="fade-in" data-aos-delay="400"></div>
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/steering-wheel_orange.png').default}
                                         alt="Steering wheel icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">2</span>.
                                        Capture
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">The device will observe and capture
                                        data autonomously.</p>
                                </div>

                                {/* 3rd item */}
                                <div className="relative flex flex-col items-center" data-aos="fade-up"
                                     data-aos-delay="400">
                                    <img className="w-16 h-16 mb-4" viewBox="0 0 64 64"
                                         xmlns="http://www.w3.org/2000/svg"
                                         src={require('../images/Icons/analyse_orange.png').default} alt="Analyse Icon">
                                    </img>
                                    <h4 className="h4 mb-2 text-gray-100"><span className="text-gray-100">3</span>.
                                        Analyse
                                    </h4>
                                    <p className="text-lg text-gray-100 text-center">Data is available in near real time
                                        and
                                        is made readily available through our many customisable platform
                                        integrations. </p>
                                </div>
                            </div>
                        </Transition>
                        <Transition
                            show={tab === 4}
                            appear={false}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                        </Transition>
                    </div>
                </div>
            </div>

            <div className="bg-gray-900">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8 md:py-12 border-t border-gray-300">
                    <div className="py-8 md:py-8">
                        <Transition
                            show={tab === 1}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h3 className="h2 mb-3 text-white text-center md:text-left">Integration</h3>
                            <div className="grid gap-20">

                                {/* Item */}
                                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                                    {/* Image */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-2 md:mb-0 md:order-1"
                                        data-aos="fade-up">
                                        <div className="relative">
                                            <svg
                                                className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto md:-top-10 md:-right-4 -top-8 -right-4"
                                                width="540" height="520" viewBox="0 0 540 520"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g className="fill-current text-blue-500">
                                                    <circle className="pulse" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-1" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-2" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-3" cx="270" cy="260" r="64"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="relative">
                                            <img className="md:max-w-full"
                                                 src={require('../images/IC6-Integration.png').default} width="100%"
                                                 height="520" alt="Features illustration"/>
                                        </div>
                                    </div>
                                    {/* Content */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                                        data-aos="fade-right">
                                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                                            <p className="text-xl text-gray-100 mb-4">IC6 integrates into a white
                                                labelled end to end platform which
                                                can be tailored to suit customer
                                                needs. Alternatively, if you have an existing solution, we can provide
                                                a secure cloud-based data vault,
                                                accessed via standard APIs or
                                                streamed to you.</p>
                                            <ul className="flex flex-wrap text-lg text-gray-100 -mx-2 -my-1">
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Real-time cloud data processing</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Remote device configuration</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Driver behaviour insights</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>API and message forwarding</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Detailed crash reporting</span>
                                                </li>
                                            </ul>
                                            <div className="flex justify-left my-8" data-aos="fade-up"
                                                 data-aos-delay="400">
                                                <a className="btn text-white border-white hover:bg-gray-100 hover:border-black"
                                                   href="/platform-solutions">Find out more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Transition>
                        <Transition
                            show={tab === 2}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h3 className="h2 mb-3 text-white text-center md:text-left">Integration</h3>
                            <div className="grid gap-20">

                                {/* Item */}
                                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                                    {/* Image */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-2 md:mb-0 md:order-1"
                                        data-aos="fade-up">
                                        <div className="relative">
                                            <svg
                                                className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto md:-top-10 md:-right-4 -top-8 -right-4"
                                                width="540" height="520" viewBox="0 0 540 520"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g className="fill-current text-yellow-500">
                                                    <circle className="pulse" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-1" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-2" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-3" cx="270" cy="260" r="64"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="relative">
                                            <img className="md:max-w-full"
                                                 src={require('../images/IC-IntegrationIC7.png').default} width="100%"
                                                 height="520" alt="Features illustration"/>
                                        </div>
                                    </div>
                                    {/* Content */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                                        data-aos="fade-right">
                                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                                            <p className="text-xl text-gray-100 mb-4">IC7 integrates into a white
                                                labelled end to end platform which
                                                can be tailored to suit customer
                                                needs. Alternatively, if you have an existing solution, we can provide
                                                a secure cloud-based data vault,
                                                accessed via standard APIs or
                                                streamed to you.</p>
                                            <ul className="flex flex-wrap text-lg text-gray-100 -mx-2 -my-1">
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Real-time cloud data processing</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Remote device configuration</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Driver behaviour insights</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>API and message forwarding</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Detailed crash reporting</span>
                                                </li>
                                            </ul>
                                            <div className="flex justify-left my-8" data-aos="fade-up"
                                                 data-aos-delay="400">
                                                <a className="btn text-white border-white hover:bg-gray-100 hover:border-black"
                                                   href="/platform-solutions">Find out more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Transition>
                        <Transition
                            show={tab === 3}
                            appear={true}
                            className="w-full"
                            enter="transition ease-in-out duration-500 transform order-first"
                            enterStart="opacity-0 scale-98"
                            enterEnd="opacity-100 scale-100"
                            leave="transition ease-out duration-300 transform absolute"
                            leaveStart="opacity-100 scale-100"
                            leaveEnd="opacity-0 scale-98"
                        >
                            {/* Items */}
                            <h3 className="h2 mb-3 text-white text-center md:text-left">Integration</h3>
                            <div className="grid gap-20">

                                {/* Item */}
                                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                                    {/* Image */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-2 md:mb-0 md:order-1"
                                        data-aos="fade-up">
                                        <div className="relative">
                                            <svg
                                                className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto md:-top-10 md:-right-4 -top-8 -right-4"
                                                width="540" height="520" viewBox="0 0 540 520"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g className="fill-current text-yellow-500">
                                                    <circle className="pulse" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-1" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-2" cx="270" cy="260" r="64"/>
                                                    <circle className="pulse pulse-3" cx="270" cy="260" r="64"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="relative">
                                            <img className="md:max-w-full"
                                                 src={require('../images/IC-IntegrationIC7.png').default} width="100%"
                                                 height="520" alt="Features illustration"/>
                                        </div>
                                    </div>
                                    {/* Content */}
                                    <div
                                        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                                        data-aos="fade-right">
                                        <div className="md:pr-4 lg:pr-12 xl:pr-16">
                                            <p className="text-xl text-gray-100 mb-4">IC7+ integrates into a white
                                                labelled end to end platform which
                                                can be tailored to suit customer
                                                needs. Alternatively, if you have an existing solution, we can provide
                                                a secure cloud-based data vault,
                                                accessed via standard APIs or
                                                streamed to you.</p>
                                            <ul className="flex flex-wrap text-lg text-gray-100 -mx-2 -my-1">
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Real-time cloud data processing</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Remote device configuration</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Driver behaviour insights</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>API and message forwarding</span>
                                                </li>
                                                <li className="flex items-center mx-2 my-1">
                                                    <svg
                                                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                                                        viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                                    </svg>
                                                    <span>Detailed crash reporting</span>
                                                </li>
                                            </ul>
                                            <div className="flex justify-left my-8" data-aos="fade-up"
                                                 data-aos-delay="400">
                                                <a className="btn text-white border-white hover:bg-gray-100 hover:border-black"
                                                   href="/platform-solutions">Find out more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Transition>
                        <div className="max-w-6xl mx-auto pb-0">
                            <h4 className="h4 sm:mb-4 text-white text-center" data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-tabs]">Check out our other solutions</h4>
                            <div
                                className="grid md:grid-cols-4 grid-cols-4 items-center md:gap-4 flex flex-wrap justify-center m-0"
                                data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 ${tab === 1 ? 'bg-gray-900 border-b-4 border-blue-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={wrapperFunction_tab1}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight md:tracking-tighter mx-1 text-blue-500 ${tab !== 1 && 'opacity-50'}`}>IC6</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 ${tab === 2 ? 'bg-gray-900 border-b-4 border-yellow-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={wrapperFunction_tab2}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight md:tracking-tighter mx-1 text-yellow-500 ${tab !== 2 && 'opacity-50'}`}>IC7</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 ${tab === 3 ? 'bg-gray-900 border-b-4 border-yellow-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={wrapperFunction_tab3}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight md:tracking-tighter mx-1 text-yellow-500 ${tab !== 3 && 'opacity-50'}`}>IC7+</h3>
                                </button>
                                <button
                                    className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1  rounded transition duration-500 ${tab === 4 ? 'bg-gray-900 border-b-4 border-pink-500' : 'bg-gray-600 border-gray-500'}`}
                                    onClick={wrapperFunction_tab4}
                                >
                                    <h3 className={`md:text-4xl text-2xl font-extrabold md:leading-tight md:tracking-tighter mx-1 text-pink-500 ${tab !== 4 && 'opacity-50'}`}>IoT</h3>
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    );
}

export default SolutionsTabs;
