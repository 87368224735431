import React from 'react';

import Device3 from '../images/DSC02624 Large.jpg';
import Device5 from '../images/DSC02655 Large.jpg';
import CarsOnMap from '../images/carsOnMap.jpg';
import CrashOnMap from '../images/crashOnMap.jpg';
import SpeedOnMap from '../images/speedOnMap.jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "../utils/GetScreenDimensions";
import Dashboard from "../images/UBIcomputer.png"
import Engineering from "../images/Engineering.jpg"
import Programming from "../images/programming.jpg"

function HeroAbout() {

    let {width} = useWindowDimensions(), slideRow = 1;
    if (width >= 600) {
        slideRow = 3
    }
    const settings = {
        infinite: true,
        speed: 500,
        fade: false,
        arrows: false,
        dots: false,
        autoplay: true,
        slidesToShow: slideRow,
        slidesToScroll: 1,
    };

    return (

        <section>
            <div className="card">
                <div className="bg-gray-900 bg-opacity-80 relative z-10 w-full mx-auto px-2 sm:px-6" data-aos="fade-in"
                     data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                    <div className=" relative z-10 md:w-5/6 mx-auto px-4 sm:px-6" data-aos="fade-in"
                         data-aos-duration="1500" data-aos-anchor-placement="top-bottom">


                        <div className="pt-12 pb-4 md:pt-10 md:pb-2  border-b border-gray-100">

                            {/* Section header */}

                            <section className="">
                                <div className="flex flex-wrap">
                                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
                                        <div className="flex lg:py-12">
                                            <div className="relative inset-0 z-20 block">
                                                <video
                                                    autoPlay
                                                    muted
                                                    loop
                                                    playsInline
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "20px"
                                                    }}
                                                >
                                                    <source
                                                        src="https://agmtwebsitevideo.s3.eu-west-1.amazonaws.com/IC6Square.mp4"
                                                        type="video/mp4"/>

                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                                        <div
                                            className="flex h-full items-center rounded-lg bg-primary p-6 text-center text-white lg:pl-12 lg:text-left">
                                            <div className="lg:pl-12">
                                                <h1 className="h2 mb-6 font-bold">
                                                    WHO ARE WE?
                                                </h1>
                                                <p className="mb-6 pb-2 lg:pb-0">
                                                    We revolutionise vehicle interaction and understanding through our
                                                    specialised application of low-cost, windscreen-mounted hardware
                                                    combined with patented software to offer pioneering analytics
                                                    solutions.
                                                </p>
                                                <p className="mb-6 pb-2 lg:pb-0">
                                                    Designed for zero installation and seamless operation, our approach
                                                    leverages vehicle data to empower insurers and fleet manages with
                                                    actionable intelligence. Enabling improved safety, optimised
                                                    performance, and reduced operational costs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="max-w-sm md:max-w-full md:w-6xl mx-auto md:col-span-12 lg:col-span-12 md:mb-8 md:order-1 w-full md:pb-0"
                                        data-aos="fade-up">
                                        <Slider {...settings}>
                                            <div>
                                                <h3><img src={Device5} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={Device3} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={Programming} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={CarsOnMap} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={Dashboard} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={CrashOnMap} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={Engineering} alt="Device"/></h3>
                                            </div>
                                            <div>
                                                <h3><img src={SpeedOnMap} alt="Device"/></h3>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroAbout;