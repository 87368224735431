import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import Footer from '../partials/Footer';
import LandingVideo from "../partials/LandingVideo";
import BlogList from "../partials/BlogList";
import OurServices from "../partials/OurServices";
import Intro from "../partials/Intro";
import ClientsHomepage from "../partials/ClientsHomepage";

function Homepage() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header/>

            {/*  Page content */}
            <main className="flex-grow">
                <LandingVideo/>

                {/*  Page sections */}
                <HeroHome/>
                <Intro/>
                <OurServices/>
                <BlogList/>
                <ClientsHomepage/>
            </main>
            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Homepage;