import React from 'react';

function ClientsHomepage() {
    return (
        <section>
            <div className="bg-gray-900 bg-opacity-90 opacity-90 max-w-full z-30 border-t border-gray-500">
                <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="py-12 md:py-10">

                        {/* Section header */}
                        <div className="z-20 max-w-3xl mx-auto text-center md:pb-0">
                            <h1 className="h2 mb-4 text-gray-100">IN PARTNERSHIP WITH</h1>
                        </div>

                        {/* Items */}
                        <div className="z-10 grid gap-2 grid-cols-2 md:grid-cols-4">

                            {/* Item */}
                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/roedan_logo_small@2x.png').default}
                                     width="1440"
                                     height="394" alt="About"/>
                            </div>

                            {/* Item */}
                            <div className="bg-white flex items-center justify-center h-24 p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/track.png').default} width="1440"
                                     height="394"
                                     alt="About"/>
                            </div>

                            {/* Item */}
                            <div className="bg-white flex items-center justify-center h-24  p-4 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/sony-logo-black-and-white.png').default}
                                     alt="Sony">
                                </img>
                            </div>

                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
                                <img className="bg-white w-1/2" src={require('../images/Logo/esa-logo.svg').default}
                                     alt="European-Space-Agency logo">
                                </img>
                            </div>

                            {/* Item */}
                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-clients]">
                                <img alt="STI"
                                     data-src="https://www.sti-limited.com/wp-content/themes/sti/src/img/logo.svg"
                                     className="logo lazyloaded"
                                     src="https://www.sti-limited.com/wp-content/themes/sti/src/img/logo.svg"/>
                            </div>

                            {/* Item */}
                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/InnovateUK.png').default}
                                     alt="Innovate UK">
                                </img>
                            </div>

                            {/* Item */}

                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/eurdf.png').default} alt="About"/>
                            </div>

                            <div className="bg-white flex items-center justify-center h-24  p-2 border rounded-lg"
                                 data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
                                <img className="" src={require('../images/Logo/Genfablogo.png').default} alt="GenFab">
                                </img>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default ClientsHomepage;