import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

function Header() {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const trigger = useRef(null);
    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({target}) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({keyCode}) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <header className="fixed w-full z-30">
            <div className="max-w-full mx-auto px-4 sm:px-6 bg-gray-900 border border-gray-800">
                <div className="flex items-center justify-between h-14">

                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-4">
                        {/* Logo */}
                        <Link to="/" className="block" aria-label="Cruip">
                            <img className="object-contain w-60 h-40 inline-block"
                                 src={require('../images/Logo/AGMTechnologies.png').default} alt="fireSpot"/>
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:flex-grow">

                        {/* Desktop menu links */}
                        <ul className="flex flex-grow justify-end flex-wrap items-center">

                        </ul>

                        {/* Desktop sign in links */}
                        <ul className="flex flex-grow justify-end flex-wrap items-center text-gray-800">
                            <li>
                                <Link to="/telematics"
                                      className="text-m text-white text-lg hover:text-gray-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">TELEMATICS
                                </Link>
                            </li>
                            <li>
                                <Link to="/platform-solutions"
                                      className="text-m text-white text-lg hover:text-gray-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">SOFTWARE</Link>
                            </li>
                            <li>
                                <Link to="/about"
                                      className="text-m text-white text-lg hover:text-gray-600 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                                    ABOUT
                                </Link>
                            </li>
                        </ul>

                    </nav>

                    {/* Mobile menu */}
                    <div className="md:hidden">

                        {/* Hamburger button */}
                        <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`}
                                aria-controls="mobile-nav" aria-expanded={mobileNavOpen}
                                onClick={() => setMobileNavOpen(!mobileNavOpen)}>
                            <span className="sr-only">Menu</span>
                            <svg
                                className="w-6 h-6 fill-current text-white hover:text-gray-700 transition duration-150 ease-in-out"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" rx="1"/>
                                <rect y="11" width="24" height="2" rx="1"/>
                                <rect y="18" width="24" height="2" rx="1"/>
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <nav id="mobile-nav" ref={mobileNav}
                             className="absolute bg-white top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
                             style={mobileNavOpen ? {
                                 maxHeight: mobileNav.current.scrollHeight,
                                 opacity: 1
                             } : {maxHeight: 0, opacity: .8}}>
                            <ul className="bg-gray-900 border border-2 border-black px-4 py-2">
                                <li className="py-2 my-2 border-t border-b border-gray-600 opacity-100 ">
                                    <li>
                                        <Link to="/telematics"
                                              className="font-medium text-xl text-gray-100 hover:text-gray-200 flex py-2 px-4 leading-tight">TELEMATICS</Link>
                                    </li>
                                    <li>
                                        <Link to="/platform-solutions"
                                              className="font-medium text-xl text-gray-100 hover:text-gray-200 flex py-2 px-4 leading-tight">SOFTWARE</Link>
                                    </li>
                                    <li>
                                        <Link to="/about"
                                              className="font-medium text-xl text-gray-100 hover:text-gray-200 flex py-2 px-4 leading-tight">ABOUT</Link>
                                    </li>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
