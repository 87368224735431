import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div className="bg-gray-900 bg-opacity-100 relative border border-gray-800 z-100 py-4 md:py-4">
                <div className="max-w-12xl mx-auto px-4 sm:px-6">
                    {/* Bottom area */}
                    <div className="flex flex-wrap justify-between items-center">

                        {/* Left aligned content - Social links and contact info */}
                        <div className="flex items-center">
                            {/* Social links */}
                            <ul className="flex mb-4 md:mb-0">
                                <li>
                                    <Link to="/linkedin"
                                          className="flex justify-center items-center text-gray-100 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                                          aria-label="Linkedin">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z"/>
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            <div className="text-gray-100 text-xs md:flex">
                                <div className="mr-4"><b>Phone</b>: +44 1172 510424</div>
                                <div className="mr-4"><b>Email</b>: info@agmtechnologies.co.uk</div>
                            </div>
                        </div>

                        {/* Center aligned content - Copyright */}
                        <div className="text-gray-100 text-xs text-center flex-1 md:flex-none md:text-right mt-4 md:mt-0 md:ml-auto">
                            &copy; 2024 AGM Technologies. All rights reserved.
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
