import React from 'react';

function Stats() {
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 bg-gray-900">
                <div className="pb-12 md:pb-20">

                    {/* Section header */}
                    <div className="max-w-5xl mx-auto text-center pb-12 md:pb-16">
                        <h1 className="h3 mb-1 text-gray-100" data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">Reliable data recorded accurately and
                            consistently</h1>
                    </div>

                    <div
                        className="grid md:grid-cols-3 divide-y md:divide-y-0 md:divide-x divide-gray-700 px-6 md:px-0 md:py-8 text-center">
                        {/* 1st item */}
                        <div className="py-6 md:py-0 md:px-8">
                            <div
                                className="text-4xl font-bold leading-tight tracking-tighter text-gray-100 hover:text-purple-500 mb-2"
                                data-aos="fade-up">6 million
                            </div>
                            <div className="text-lg text-gray-100" data-aos="fade-up" data-aos-delay="200"> Messages
                                ingested per day
                            </div>
                        </div>
                        {/* 2nd item */}
                        <div className="py-6 md:py-0 md:px-8">
                            <div
                                className="text-4xl font-bold leading-tight tracking-tighter text-gray-100 hover:text-purple-500 mb-2"
                                data-aos="fade-up">230,000
                            </div>
                            <div className="text-lg text-gray-100" data-aos="fade-up" data-aos-delay="200">Driving
                                events processed per day
                            </div>
                        </div>
                        {/* 3rd item */}
                        <div className="py-6 md:py-0 md:px-8">
                            <div
                                className="text-4xl font-bold leading-tight tracking-tighter text-gray-100 hover:text-purple-500 mb-2"
                                data-aos="fade-up">450 million
                            </div>
                            <div className="text-lg text-gray-100" data-aos="fade-up" data-aos-delay="400">Miles
                                travelled per year
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stats;