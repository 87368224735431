import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import AboutUsIntro from '../partials/AboutUsIntro';
import Team from '../partials/Team';
import Clients from '../partials/Clients';
import Footer from '../partials/Footer';
import EnvironmentalPolicy from "../partials/EnvironmentalPolicy";


function About() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden bg-gray-900">

            {/*  Site header */}
            <Header/>

            {/*  Page content */}
            <main className="flex-grow">

                {/*  Page illustration */}
                <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                    <PageIllustration/>
                </div>

                {/*  Page sections */}
                <AboutUsIntro/>
                <Team/>
                <EnvironmentalPolicy/>
                <Clients/>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default About;