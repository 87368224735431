import React from 'react';

function ContactUsPartial() {
    return (
        <section>
            <div className="bg-gray-900 w-full mx-auto px-4 sm:px-6 relative">
                <div className="pt-12 md:pt-20 border-t border-b border-gray-100">
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">

                        {/* Section header */}
                        <h1 className="h2 mb-4 text-gray-100" data-aos="fade-up"
                        >Contact us for a
                            demo.</h1>
                        <p className="text-xl text-gray-100 mb-8" data-aos="fade-up" data-aos-delay="200"
                        >Get in touch for further information, with trial devices
                            available on request.</p>

                        {/* CTA button */}
                        <div className="flex justify-center mb-8" data-aos="fade-up" data-aos-delay="400"
                        >
                            <a className="btn text-gray-100 border-gray-100 hover:bg-gray-100 hover:border-black hover:text-black"
                               href="mailto: info@agmtechnologies.co.uk?subject = Feedback&body = Demo">Contact
                                us</a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUsPartial;
