import React from 'react';
import Slider from "react-slick";
import Device5 from "../images/DSC02550 Large.jpg";
import Device from "../images/UBIcomputer.png";
import Device3 from "../images/DSC02608 Large.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "../utils/GetScreenDimensions";

function AboutUsIntro() {


    let {width} = useWindowDimensions(), slideRow = 1;
    if (width >= 600) {
        slideRow = 3
    }

    const settings = {
        infinite: true,
        speed: 500,
        fade: false,
        arrows: true,
        dots: true,
        autoplay: true,
        slidesToShow: slideRow,
        slidesToScroll: 1
    };

    return (
        <section className="relative">

            {/* Hero content */}

            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="pt-32 pb-12 md:pt-40 md:pb-2">
                    <div className="max-w-5xl mx-auto">
                        <h1 className="h1 mb-4 text-gray-100 text-center" data-aos="fade-up">ABOUT US</h1>
                        <p className="text-xl md:text-xl text-gray-200 mb-2 text-center md:text-left pb-8"
                           data-aos="fade-up"
                           data-aos-delay="200">
                            AGM Technologies was originally launched to provide innovative telematic solutions to the
                            world’s leading insurers. With a combined 50+ years of insurance experience, our founders
                            focused on utilising technology to improve insurance solutions at an affordable rate for
                            both the insurer and the end user. We specialised in the application of low cost, windscreen
                            mounted hardware and combined this with our patented software to accurately evaluate
                            insurance risk.
                        </p>
                        <div
                            className="max-w-sm md:max-w-full md:w-6xl mx-auto md:col-span-12 lg:col-span-12 md:mb-8 md:order-1 w-full pb-8 md:pb-0"
                            data-aos="fade-up">
                            <Slider {...settings}>
                                <div>
                                    <h3><img src={Device5} alt="Device"/></h3>
                                </div>
                                <div>
                                    <h3><img src={Device} alt="Device"/></h3>
                                </div>
                                <div>
                                    <h3><img src={Device3} alt="Device"/></h3>
                                </div>
                            </Slider>
                        </div>
                        <p className="text-xl md:text-xl text-gray-200 mb-8 text-center md:text-left" data-aos="fade-up"
                           data-aos-delay="200">
                            As we innovated our solutions became more and more attractive for other use cases. As a
                            result, our technology is now deployed across several continents in multiple different
                            markets. Exploiting the same self-installation as our insurance offering, our analytics
                            solutions offer a highly accurate, low-cost alternative to those devices already in the
                            market.
                        </p>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default AboutUsIntro;