import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutUsIntro() {
    return (<section className="relative">

        {/* Hero content */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-2">
                <div className="max-w-5xl mx-auto">
                    <h1 className="h1 mb-4 text-gray-900 text-center" data-aos="fade-up">Privacy Policy</h1>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">What is the purpose of this
                        privacy notice?</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        This privacy notice aims to give you information on how we collect and process your personal
                        data through your use of this website and our apps, including any data you may provide
                        through this website when you post comments to blog or page entries.
                        <br/><br/>
                        This website is not intended for children and we do not knowingly collect data relating to
                        children.
                        <br/><br/>
                        You must read this privacy notice together with any other privacy notice we may provide on
                        specific occasions when we are collecting or processing personal data about you so that you
                        are fully aware of how and why we are using your data. This privacy notice supplements the
                        other notices and is not intended to override them.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Data Controller</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        AGM Technologies Limited is the controller and responsible for your personal data
                        (collectively referred to as “AGMT”, “we”, “us” or “our” in this privacy notice). Our
                        contact details are AGM Technologies Limited, 9 Elborough Gardens, Elborough, Weston-Super-Mare,
                        BS24 8PL.
                        07717 646302. For all data matters contact our data
                        representative at the above address.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Third-party links outside of
                        our control</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        This website or our apps may include links to third-party websites, plug-ins and
                        applications. Clicking on those links or enabling those connections may allow third parties
                        to collect or share data about you. We do not control these third-party websites and are not
                        responsible for their privacy statements.
                        <br/><br/>
                        When you leave our website, we encourage you to read the privacy notice of every website you
                        visit.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">The personal information we
                        collect about you</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        Personal data, or personal information, means any information about an individual from which
                        that person can be identified. You can find out more about personal data from the
                        Information Commissioners Office.
                        <br/><br/>
                        We may collect, use, store and transfer different kinds of personal data about you which we
                        have grouped together follows:
                        <br/><br/>
                        Identity Data includes Username, name and date of birth.
                        <br/>
                        Contact Data includes email address, and telephone numbers.
                        <br/>
                        We also collect, use and share aggregated data such as statistical or demographic data for
                        any purpose. Aggregated data may be derived from your personal data but is not considered
                        personal data in law as this data does not directly or indirectly reveal your identity.
                        <br/>
                        For example, we may aggregate your usage data to calculate the percentage of users accessing
                        a specific feature/ However, if we combine or connect aggregated data with your personal
                        data so that it can directly or indirectly identify you, we treat the combined data as
                        personal data which will be used in accordance with this privacy notice.
                        <br/><br/>
                        We do not collect any Special Categories of Personal Data about you (this includes details
                        about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                        orientation, political opinions, trade union membership, information about your health and
                        genetic and biometric data). Nor do we collect any information about criminal convictions
                        and offences.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">If you fail to provide
                        personal data</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        Where we need to collect your personal data by law, or under the terms of a contract we have
                        with you and you fail to provide that data when requested, we may not be able to perform the
                        contract we have or are trying to enter into with you (for example, to provide you with
                        goods or services). In this case, we may have to cancel a product or service you have with
                        us but we will notify you if this is the case at the time.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">How we collect personal
                        data</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We use different methods to collect data from and about you including through:
                        <br/><br/>
                        <ul>
                            <li>
                                Directly. You may give us your identity and contact data by filling in forms or by
                                corresponding with us by post, phone, email or otherwise. This includes personal
                                data you provide when you:
                            </li>
                            <li>
                                Apply for our products or services;
                            </li>
                            <li>
                                Create an account on our website;
                            </li>
                            <li>
                                Subscribe to our service or publications;
                            </li>
                            <li>
                                Request marketing to be sent to you.
                            </li>
                            <li>
                                Register via our apps: You may give us your identity and contact data when you
                                register to use one of our apps
                            </li>
                        </ul>
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">How we use your personal
                        data</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We will only use your personal data when the law allows us to. Most commonly, we will use
                        your personal data in the following circumstances:
                        <br/><br/>
                        Performance of Contract this means processing your data where it is necessary for the
                        performance of a contract to which you are a party or to take steps at your request before
                        entering into such a contract.
                        <br/><br/>
                        Legitimate Interest this means the interest of our business in conducting and managing our
                        business to enable us to give you the best service/product and the most secure experience.
                        We make sure we consider and balance any potential impact on you (both positive and
                        negative) and your rights before we process your personal data for our legitimate interests.
                        We do not use your personal data for activities where our interests are overridden by the
                        impact on you (unless we have your consent or are otherwise required or permitted to by
                        law). You can obtain further information about how we assess our legitimate interests
                        against any potential impact on you in respect of specific activities by contacting the data
                        controller.
                        <br/><br/>
                        Comply with a legal or regulatory obligation this means processing your personal data where
                        it is necessary for compliance with a legal or regulatory obligation that we are subject to.
                        Generally we do not rely on consent as a legal basis for processing your personal data other
                        than in relation to sending third party direct marketing communications to you via email or
                        text message. You have the right to withdraw consent to marketing at any time by contacting
                        the data controller.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Purposes for which we will
                        use your personal data</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        Purpose/Activity: To register you as a new user
                        <br/>
                        Type of data: Identity & Contact
                        <br/>
                        Lawful basis for processing including basis of legitimate interest:
                        <br/> Performance of a contract with you
                    </p>

                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Cookies</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        You can set your browser to refuse all or some browser cookies, or to alert you when websites
                        set or access cookies. If you disable or refuse cookies, please note that some parts of this
                        website may become inaccessible or not function properly.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Change of purpose</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We will only use your personal data for the purposes for which we collected it, unless we
                        reasonably consider that we need to use it for another reason and that reason is compatible
                        with the original purpose. If you wish to get an explanation as to how the processing for
                        the new purpose is compatible with the original purpose, please contact the data controller.
                        <br/><br/>
                        If we need to use your personal data for an unrelated purpose, we will notify you and we
                        will explain the legal basis which allows us to do so.
                        Please note that we may process your personal data without your knowledge or consent, in
                        compliance with the above rules, where this is required or permitted by law.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Who we share your personal
                        data with</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We may have to share your personal data with the parties set out below for the purposes set
                        out in the table above.
                        <br/><br/>
                        Professional advisers acting as processors or joint controllers including lawyers, bankers,
                        auditors and insurers based within the EEA who provide consultancy, banking, legal, insurance
                        and accounting services.
                        <br/><br/>
                        HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers
                        based in the United Kingdom who require reporting of processing activities in certain
                        circumstances.
                        <br/><br/>
                        Third parties to whom we may choose to sell, transfer, or merge parts of our business or our
                        assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change
                        happens to our business, then the new owners may use your personal data in the same way as set
                        out in this privacy notice.
                        <br/><br/>
                        We require all third parties to respect the security of your personal data and to treat it in
                        accordance with the law. We do not allow our third-party service providers to use your personal
                        data for their own purposes and only permit them to process your personal data for specified
                        purposes and in accordance with our instructions.
                    </p>

                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">International transfers</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We do not transfer your personal data outside the European Economic Area (EEA).
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Data security</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We have put in place appropriate security measures to prevent your personal data from being
                        accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In
                        addition, we limit access to your personal data to those employees, agents, contractors and
                        other third parties who have a business need to know. They will only process your personal
                        data on our instructions and they are subject to a duty of confidentiality.
                        <br/><br/>
                        We have put in place procedures to deal with any suspected personal data breach and will
                        notify you and any applicable regulator of a breach where we are legally required to do so.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Data Retention</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        By law we have to keep basic information about our customers including contact, identity,
                        financial and transaction data for six years after they cease being customers for tax purposes.
                        <br/><br/>
                        In some circumstances you can ask us to delete your data: see Your legal rights below for
                        further information.
                        <br/><br/>
                        In some circumstances we may anonymise your personal data (so that it can no longer be
                        associated with you) for research or statistical purposes in which case we may use this
                        information indefinitely without further notice to you.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Your legal rights</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        Unless subject to an exemption under the data protection laws, you have the following rights
                        with respect to your personal data:
                        <br/><br/>
                        The right to request a copy of the personal data which we hold about you;
                        <br/><br/>
                        The right to request that we correct any personal data if it is found to be inaccurate or out of
                        date;
                        <br/><br/>
                        The right to request your personal data is erased where it is no longer necessary to retain such
                        data;
                        <br/><br/>
                        The right to withdraw your consent to the processing at any time, where consent was the lawful
                        basis for processing your data;
                        <br/><br/>
                        The right to request that we provide you with your personal data and where possible, to transmit
                        that data directly to another data controller, (known as the right to data portability), where
                        applicable (i.e. where our processing is based on consent or is necessary for the performance of
                        our contract with you or where we process your data by automated means);
                        <br/><br/>
                        The right, where there is a dispute in relation to the accuracy or processing of your personal
                        data, to request a restriction is placed on further processing;
                        <br/><br/>
                        The right to object to our processing of personal data, where applicable i.e. where processing
                        is based on our legitimate interests (or in performance of a task in the public
                        interest/exercise of official authority); direct marketing or processing for the purposes of
                        scientific/historical research and statistics).
                        <br/><br/>
                        If you wish to exercise any of the rights set out above, please contact the data controller.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">No fee required – with some
                        exceptions</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        You will not have to pay a fee to access your personal data (or to exercise any of the other
                        rights). However, we may charge a reasonable admin fee if your request is clearly unfounded,
                        repetitive or excessive. Alternatively, we may refuse to comply with your request in these
                        circumstances.
                    </p>
                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">What we may need from
                        you</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We may need to request specific information from you to help us confirm your identity and ensure
                        your right to access your personal data (or to exercise any of your other rights). This is a
                        security measure to ensure that personal data is not disclosed to any person who has no right to
                        receive it. We may also contact you to ask you for further information in relation to your
                        request to speed up our response.
                    </p>

                    <h2 className="h4 mb-4 text-gray-600 text-left" data-aos="fade-up">Time limit to respond</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We try to respond to all legitimate requests within one month. Occasionally it may take us
                        longer than a month if your request is particularly complex or you have made a number of
                        requests. In this case, we will notify you and keep you updated.
                    </p>
                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Changes to this notice and
                        your duty to inform us of changes</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        This version was last updated on 1st March 2023
                        <br/><br/>
                        Please keep us informed if your personal data changes during your relationship with us. It is
                        important that the personal data we hold about you is accurate and current.
                    </p>

                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Queries, requests or
                        concerns</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        To exercise all relevant rights, queries or complaints in relation to this policy or any other
                        data protection matter between you and us, please in the first instance contact the data
                        controller.
                        <br/><br/>
                        If this does not resolve your complaint to your satisfaction, you have the right to lodge a
                        complaint with the Information Commissioners Office on 03031231113 or via email
                        https://ico.org.uk/global/contact-us/email/ or at the Information Commissioner’s Office,
                        Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.
                    </p>

                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">Use of Location Data in Our
                        Apps</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        Our iOS and Android apps utilize location information for specific functionalities related to
                        our services. This includes:
                        <br/><br/>
                        <ul>
                            <li>
                                Tracking in the Background: Our apps may collect location data while running in the
                                background to enable tracking and provide location-based services. This is essential for
                                features such as real-time tracking of our bespoke Bluetooth device.
                            </li>
                            <li>
                                Bluetooth Device Search: Our apps use your location data to search for and connect to
                                our bespoke Bluetooth devices. This functionality ensures accurate device location and
                                connection performance.
                            </li>
                        </ul>
                    </p>

                    <h2 className="h3 mb-4 text-gray-900 text-left" data-aos="fade-up">How We Handle Location Data</h2>
                    <p className="text-lg md:text-sm text-gray-800 mb-2 text-center md:text-left pb-2"
                       data-aos="fade-up"
                       data-aos-delay="200">
                        We understand the sensitivity of location data and are committed to handling it securely and transparently. Here is how we manage location data:
                        <br/><br/>
                        <ol>
                            <li>
                                Limiting Access: We limit access to location data to the functionalities necessary for
                                our app services, which are reasonably expected by the user.
                            </li>
                            <li>
                                Data Security: All location data is transmitted securely using modern cryptography (for
                                example, over HTTPS).
                            </li>
                            <li>
                                User Consent: We request user consent before accessing location data through runtime
                                permissions. Users can manage their location data permissions in their device settings.
                            </li>
                            <li>
                                No Data Sale: We do not sell personal and sensitive user data, including location data,
                                to third parties.
                            </li>
                            <li>
                                Data Sharing: We may share location data with service providers who assist in delivering our services or for legal reasons, such as compliance with a valid governmental request. We ensure that these third parties adhere to strict data protection standards.
                            </li>
                            <li>
                                Purpose Limitation: Location data is used solely for app functionality and service delivery as outlined in this privacy notice. We do not use it for unrelated purposes without obtaining explicit user consent.
                            </li>
                        </ol>

                        <br/>
                        By using our apps, you agree to the collection and use of location data as described above. If you have any concerns about how we handle your location data, please contact our data controller.
                    </p>

                </div>
            </div>
        </div>

    </section>);
}

export default AboutUsIntro;