import React, {useRef, useState} from 'react';
import Transition from '../utils/Transition.js';

function PlatformTabs() {

    const [tab, setTab] = useState(1);
    const tabs = useRef(null);
    let CloudImage = require('../images/SVGs/ICCloud.svg').default
    let DeviceManager = require('../images/deviceManager.jpg').default
    let Dashboard = require('../images/UBI3.png').default
    let Mobile = require('../images/SVGs/mobile1.svg').default
    return (<section>
            <div className="max-w-full">
                <div className="mx-auto">
                    <div className="pt-20 md:pt-20 pb-14 md:pt-14 bg-gray-900">

                        {/* Section header */}
                        <div className="max-w-3xl mx-auto text-center pb-1 pt-10" data-aos-id-tabs>

                            <h2 className="h1 mb-4 text-white tracking-wide" data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-tabs]">SOFTWARE</h2>
                            <p className="sm:text-xl text-md text-gray-200" data-aos="fade-up" data-aos-delay="200"
                               data-aos-anchor="[data-aos-id-tabs]">Scalable, flexible, fully agnostic architecture.</p>
                        </div>

                        {/* Tabscontent */}
                        <div>

                            {/* SolutionsTabs buttons */}
                            <div className="max-w-6xl px-8 mx-auto py-4">
                                <div
                                    className="grid md:grid-cols-5 grid-cols-2 flex flex-wrap justify-center  border-b border-gray-400 text-gray-800"
                                    data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-tabs]">
                                    <button
                                        className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-green-600 ${tab !== 1 && 'bg-gray-600 border-gray-500'} ${tab === 1 && 'bg-gray-900 border-4 border-green-500'}`}
                                        onClick={() => setTab(1)}
                                    >
                                        <h3 className="text-md md:text-2xl font-bold -mx-4 text-white">IC Cloud</h3>
                                    </button>
                                    <button
                                        className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-blue-600 ${tab !== 2 && 'bg-gray-600 border-gray-500'} ${tab === 2 && 'bg-gray-900 border-4 border-blue-600'}`}
                                        onClick={() => setTab(2)}
                                    >
                                        <h3 className="text-md md:text-2xl font-bold -mx-4 text-white">Device
                                            Manager</h3>
                                    </button>
                                    <button
                                        className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-yellow-600 ${tab !== 3 && 'bg-gray-600 border-gray-500'} ${tab === 3 && 'bg-gray-900 border-4 border-yellow-600'}`}
                                        onClick={() => setTab(3)}
                                    >
                                        <h3 className="text-md md:text-2xl font-bold mx-2 text-white">Dashboard</h3>
                                    </button>
                                    <button
                                        className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-purple-600 ${tab !== 4 && 'bg-gray-600 border-gray-500'} ${tab === 4 && 'bg-gray-900 border-4 border-purple-600'}`}
                                        onClick={() => setTab(4)}
                                    >
                                        <h3 className="text-md md:text-2xl font-bold mx-2 text-white">Mobile
                                            Applications</h3>
                                    </button>
                                    <button
                                        className={`justify-center flex items-center font-medium md:px-12 px-2 m-2 md:py-1 rounded transition duration-500 hover:bg-red-500 ${tab !== 5 && 'bg-gray-600 border-gray-500'} ${tab === 5 && 'bg-gray-900 border-4 border-red-500'}`}
                                        onClick={() => setTab(5)}
                                    >
                                        <h3 className="text-md md:text-2xl font-bold mx-2 text-white">Collision
                                            Detection</h3>
                                    </button>

                                </div>
                            </div>

                            {/* SolutionsTabs items */}
                            <div className="bg-gray-900">
                                <div className="max-w-6xl mx-auto relative flex flex-col border-b border-gray-400 px-4"
                                     data-aos="fade-up" ref={tabs}>

                                    {/* IC Cloud */}
                                    <Transition
                                        show={tab === 1}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-500 transform order-first"
                                        enterStart="opacity-0 scale-98"
                                        enterEnd="opacity-100 scale-100"
                                        leave="transition ease-out duration-300 transform absolute"
                                        leaveStart="opacity-100 scale-100"
                                        leaveEnd="opacity-0 scale-98"
                                    >
                                        <div className="pb-3 md:pb-2">

                                            {/* Items */}
                                            <div className="grid gap-20" data-aos-id-target>
                                                <div className="container mx-auto">
                                                    <div className="flex flex-wrap items-center">
                                                        <div
                                                            className="w-10/12 md:w-6/12 lg:w-1/2 px-12 md:px-4 mr-auto ml-auto -mt-78">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-green-600">
                                                                <img alt="..."
                                                                     src={CloudImage}
                                                                     className="w-full align-middle rounded-t-lg bg-gray-900 p-4"/>
                                                                <blockquote className="relative p-8 mb-4">
                                                                    <svg preserveAspectRatio="none"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 583 95"
                                                                         className="absolute left-0 w-full block h-95-px -top-94-px">
                                                                    </svg>
                                                                    <h4 className="text-xl font-bold text-white">
                                                                        IC Cloud
                                                                    </h4>
                                                                    <p className="text-md font-light mt-2 text-white">
                                                                        The IC Cloud is a centralised data hub designed
                                                                        to seamlessly aggregate information from all
                                                                        connected devices in your IC devices ecosystem,
                                                                        ensuring it's accessible from a single location.
                                                                        It's built for scalability, anonymization and
                                                                        event processing, the IC Cloud guarantees data
                                                                        privacy and integrity, while providing options
                                                                        to enhance data interoperability across systems.
                                                                    </p>
                                                                </blockquote>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-6/12 px-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div className="relative flex flex-col mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-500">
                                                                                <i className="fas fa-sitemap"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Scalable</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Adeptly handles extreme peaks in data
                                                                                traffic, ensuring consistent performance
                                                                                even as device counts and data volumes
                                                                                increase.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-500">
                                                                                <i className="fas fa-file-alt"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">
                                                                                Resilient
                                                                            </h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Incorporating in-built failovers that
                                                                                guarantee uninterrupted service and data
                                                                                integrity during system failures.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div
                                                                        className="relative flex flex-col min-w-0 mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-500">
                                                                                <i className="fas fa-lock"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Privacy</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Equipped with advanced tools to
                                                                                anonymize or pseudonymise data, it
                                                                                ensures user privacy and compliance with
                                                                                data protection laws.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-500">
                                                                                <i className="fas fa-network-wired"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Interoperability</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Flexible push and pull options,
                                                                                facilitating seamless data exchange with
                                                                                other systems.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>

                                    {/* Device Manager*/}
                                    <Transition
                                        show={tab === 2}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-500 transform order-first"
                                        enterStart="opacity-0 scale-98"
                                        enterEnd="opacity-100 scale-100"
                                        leave="transition ease-out duration-300 transform absolute"
                                        leaveStart="opacity-100 scale-100"
                                        leaveEnd="opacity-0 scale-98"
                                    >
                                        <div className="pb-3 md:pb-2">

                                            {/* Items */}
                                            <div className="grid gap-20" data-aos-id-target>
                                                <div className="container mx-auto">
                                                    <div className="flex flex-wrap items-center">
                                                        <div
                                                            className="w-10/12 md:w-6/12 lg:w-1/2 px-12 md:px-4 mr-auto ml-auto -mt-78">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                                                                <img alt="..."
                                                                     src={DeviceManager}
                                                                     className="w-full align-middle rounded-t-lg"/>
                                                                <blockquote className="relative p-8 mb-4">
                                                                    <svg preserveAspectRatio="none"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 583 95"
                                                                         className="absolute left-0 w-full block h-95-px -top-94-px">
                                                                    </svg>
                                                                    <h4 className="text-xl font-bold text-white">
                                                                        Device Manager
                                                                    </h4>
                                                                    <p className="text-md font-light mt-2 text-white">
                                                                        Device Manager serves as the central command hub
                                                                        for the IC ecosystem, enabling comprehensive
                                                                        control over all devices from a single unified
                                                                        platform. It simplifies the complex task of
                                                                        managing diverse devices spread across various
                                                                        locations by offering an interface where you can
                                                                        monitor performance, update firmware, and
                                                                        troubleshoot issues.
                                                                    </p>
                                                                </blockquote>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-6/12 px-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div className="relative flex flex-col mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-600">
                                                                                <i className="fas fa-desktop"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Monitor</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Ensure optimal performance and security
                                                                                through monitoring of device health and
                                                                                status.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-600">
                                                                                <i className="fas fa-wifi"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">
                                                                                Over The Air
                                                                            </h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Effortlessly update and configure
                                                                                devices remotely, enhancing operational
                                                                                efficiency without physical access.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div
                                                                        className="relative flex flex-col min-w-0 mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-600">
                                                                                <i className="fas fa-newspaper"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Manage</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Simplify management of partner or client
                                                                                identities and device ownership,
                                                                                ensuring clear control.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-600">
                                                                                <i className="fas fa-truck"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Delivery</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Track every step of device shipments
                                                                                with precision, from warehouse departure
                                                                                to final delivery for complete
                                                                                visibility.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>

                                    {/* Dashboard */}
                                    <Transition
                                        show={tab === 3}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-500 transform order-first"
                                        enterStart="opacity-0 scale-98"
                                        enterEnd="opacity-100 scale-100"
                                        leave="transition ease-out duration-300 transform absolute"
                                        leaveStart="opacity-100 scale-100"
                                        leaveEnd="opacity-0 scale-98"
                                    >
                                        <div className="pb-3 md:pb-2">

                                            {/* Items */}
                                            <div className="grid gap-20" data-aos-id-target>
                                                <div className="container mx-auto">
                                                    <div className="flex flex-wrap items-center">
                                                        <div
                                                            className="w-10/12 md:w-6/12 lg:w-1/2 px-12 md:px-4 mr-auto ml-auto -mt-78">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-yellow-600">
                                                                <img alt="..."
                                                                     src={Dashboard}
                                                                     className="w-full align-middle rounded-t-lg"/>
                                                                <blockquote className="relative p-8 mb-4">
                                                                    <svg preserveAspectRatio="none"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 583 95"
                                                                         className="absolute left-0 w-full block h-95-px -top-94-px">
                                                                    </svg>
                                                                    <h4 className="text-xl font-bold text-white">
                                                                        Dashboard
                                                                    </h4>
                                                                    <p className="text-md font-light mt-2 text-white">
                                                                        Our dashboard offers a comprehensive view of
                                                                        driving data from your devices, presenting
                                                                        actionable insights and performance metrics in a
                                                                        user-friendly interface. It enables users to
                                                                        monitor recent journeys, track historical
                                                                        trends, and identify areas for improvement or
                                                                        concern. Designed for ease of use, the dashboard
                                                                        simplifies the complexity of device data, making
                                                                        it accessible for decision-making and analysis.
                                                                    </p>
                                                                </blockquote>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-6/12 px-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div className="relative flex flex-col mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-600">
                                                                                <i className="fas fa-chart-bar"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Analytics</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Monitor each driver individually or
                                                                                compare against others, offering a deep
                                                                                dive into device efficiency and usage
                                                                                patterns.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-600">
                                                                                <i className="fas fa-exclamation-circle"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">
                                                                                Tamper & Inactivity Alerts
                                                                            </h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Receive notifications for any signs of
                                                                                device tampering or removal, ensuring
                                                                                the integrity and security of your
                                                                                deployed devices.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div
                                                                        className="relative flex flex-col min-w-0 mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-600">
                                                                                <i className="fas fa-list-alt"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Comprehensive</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Detailed views of each journey, offering
                                                                                insights into usage and enabling
                                                                                thorough evaluation of driver’s
                                                                                performance over time.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-600">
                                                                                <i className="fas fa-car-crash"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Collision
                                                                                Reporting</h6>
                                                                            <p className="mb-4 text-blueGray-500">Dedicated
                                                                                to providing comprehensive collision
                                                                                monitoring, this feature allows insurers
                                                                                to accurately evaluate potential crash
                                                                                events, reduce claims through detailed
                                                                                incident analysis.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>

                                    {/* Item 4 */}
                                    <Transition
                                        show={tab === 4}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-500 transform order-first"
                                        enterStart="opacity-0 scale-98"
                                        enterEnd="opacity-100 scale-100"
                                        leave="transition ease-out duration-300 transform absolute"
                                        leaveStart="opacity-100 scale-100"
                                        leaveEnd="opacity-0 scale-98"
                                    >
                                        <div className="pb-3 md:pb-2">

                                            {/* Items */}
                                            <div className="grid gap-20" data-aos-id-target>
                                                <div className="container mx-auto">
                                                    <div className="flex flex-wrap items-center">
                                                        <div
                                                            className="w-10/12 md:w-6/12 lg:w-1/2 px-12 md:px-4 mr-auto ml-auto -mt-78">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-purple-600">
                                                                <img alt="..."
                                                                     src={Mobile}
                                                                     className="w-full align-middle rounded-t-lg bg-gray-900 p-4"/>
                                                                <blockquote className="relative p-8 mb-4">
                                                                    <svg preserveAspectRatio="none"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 583 95"
                                                                         className="absolute left-0 w-full block h-95-px -top-94-px">
                                                                    </svg>
                                                                    <h4 className="text-xl font-bold text-white">
                                                                        Mobile Applications
                                                                    </h4>
                                                                    <p className="text-md font-light mt-2 text-white">
                                                                        Our mobile applications, compatible with both
                                                                        iOS and Android platforms, offer an intuitive
                                                                        and user-friendly interface that bridges
                                                                        companies directly with end-users. They serve as
                                                                        a seamless conduit for highlighting areas of
                                                                        improvement or extending commendations,
                                                                        enhancing the direct line of communication and
                                                                        fostering a dynamic feedback loop.
                                                                    </p>
                                                                </blockquote>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-6/12 px-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div className="relative flex flex-col mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-600">
                                                                                <i className="fas fa-tag"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Fully
                                                                                White Labelled and Configurable</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Our solution is fully white-labelled, allowing your brand to
                                                                                shine through the app’s design, tailoring the user
                                                                                experience to meet your specific needs.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-600">
                                                                                <i className="fas fa-star"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">
                                                                                Scoring
                                                                            </h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Feature driver scoring and
                                                                                detailed journey breakdowns, offering
                                                                                insights into driving habits and areas
                                                                                for improvement, enhancing driver safety
                                                                                and performance.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div
                                                                        className="relative flex flex-col min-w-0 mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-600">
                                                                                <i className="fas fa-phone"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Direct
                                                                                Contact with End User</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Establish a direct line of communication
                                                                                with end-users, facilitating
                                                                                feedback and engagement to drive
                                                                                customer satisfaction and loyalty.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-600">
                                                                                <i className="fas fa-wrench"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Self-Install</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Includes an easy-to-follow self-install
                                                                                guide for all devices, ensuring a smooth
                                                                                setup process for users and minimizing
                                                                                the need for technical support.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>

                                    {/* Collision Detection*/}
                                    <Transition
                                        show={tab === 5}
                                        appear={true}
                                        className="w-full"
                                        enter="transition ease-in-out duration-500 transform order-first"
                                        enterStart="opacity-0 scale-98"
                                        enterEnd="opacity-100 scale-100"
                                        leave="transition ease-out duration-300 transform absolute"
                                        leaveStart="opacity-100 scale-100"
                                        leaveEnd="opacity-0 scale-98"
                                    >
                                        <div className="pb-3 md:pb-2">

                                            {/* Items */}
                                            <div className="grid gap-20" data-aos-id-target>
                                                <div className="container mx-auto">
                                                    <div className="flex flex-wrap items-center">
                                                        <div
                                                            className="w-10/12 md:w-6/12 lg:w-1/2 px-12 md:px-4 mr-auto ml-auto -mt-78">
                                                            <div
                                                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-500">
                                                                <div className="py-0 bg-gray-900">
                                                                    <div className="relative">
                                                                        {/* Map */}
                                                                        <div
                                                                            className="absolute inset-0 flex justify-center items-end"
                                                                            aria-hidden="true">
                                                                        </div>
                                                                        {/* People pics */}
                                                                        <img
                                                                            src={require('../images/worldmap.png').default}
                                                                            width="672" height="330"
                                                                            alt="World map"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '22%', left: '52%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="32" height="32"
                                                                             alt="User 01" data-aos="zoom-in"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '50%', left: '58%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="48" height="48"
                                                                             alt="User 02" data-aos="zoom-in"
                                                                             data-aos-delay="400"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '8.5%', left: '70.5%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="56" height="56"
                                                                             alt="User 02" data-aos="zoom-in"
                                                                             data-aos-delay="100"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '34%', left: '76.5%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="72" height="72"
                                                                             alt="User 04" data-aos="zoom-in"
                                                                             data-aos-delay="700"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '29.5%', left: '8.5%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="48" height="48"
                                                                             alt="User 05" data-aos="zoom-in"
                                                                             data-aos-delay="500"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '56%', left: '19%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="56" height="56"
                                                                             alt="User 06" data-aos="zoom-in"
                                                                             data-aos-delay="200"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '20%', left: '24.5%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="64" height="64"
                                                                             alt="User 07" data-aos="zoom-in"
                                                                             data-aos-delay="600"/>
                                                                        <img className="absolute rounded-full"
                                                                             style={{top: '39%', left: '43%'}}
                                                                             src={require('../images/Icons/car-crash.png').default}
                                                                             width="40" height="40"
                                                                             alt="User 08" data-aos="zoom-in"
                                                                             data-aos-delay="300"/>
                                                                    </div>
                                                                </div>
                                                                <blockquote className="relative p-8 mb-4">

                                                                    <h4 className="text-xl font-bold text-white">
                                                                        Collision Detection
                                                                    </h4>
                                                                    <p className="text-md font-light mt-2 text-white">
                                                                        Our devices leverage a blend of cutting-edge
                                                                        hardware and sophisticated software development,
                                                                        featuring our proprietary collision detection
                                                                        technology. This innovation allows for precise
                                                                        detection, recording, and analysis of impacts,
                                                                        ensuring accurate evaluations of collision
                                                                        events.
                                                                    </p>
                                                                </blockquote>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-6/12 px-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div className="relative flex flex-col mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500">
                                                                                <i className="fas fa-check"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Accurate
                                                                                Event Discrimination</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Our accelerometer array captures the
                                                                                severity, direction, and multiple
                                                                                impacts of an event, while filtering out
                                                                                environmental noise to reduce false
                                                                                positives
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500">
                                                                                <i className="fas fa-map-marker"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">
                                                                                Local Event Detection
                                                                            </h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Crash events are detected in real-time
                                                                                directly on the device, not in the
                                                                                cloud, ensuring low data overhead by
                                                                                transmitting only valid events over the
                                                                                network.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-6/12 px-4">
                                                                    <div
                                                                        className="relative flex flex-col min-w-0 mt-4">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500">
                                                                                <i className="fas fa-file"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">Analytical
                                                                                Data Provision</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                A "camera roll" of data capturing
                                                                                crash impacts before, during,
                                                                                and after the event is sent, where qualified events undergo
                                                                                analysis to develop new event profiles.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex flex-col min-w-0">
                                                                        <div className="px-4 py-5 flex-auto">
                                                                            <div
                                                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-500">
                                                                                <i className="fas fa-wifi"></i>
                                                                            </div>
                                                                            <h6 className="text-xl mb-1 font-semibold">"Over
                                                                                the Air" Configuration</h6>
                                                                            <p className="mb-4 text-blueGray-500">
                                                                                Configurable event profiles stored
                                                                                locally can be set and deployed
                                                                                remotely, either globally or to
                                                                                individual devices, offering flexible
                                                                                management options.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default PlatformTabs;
