import React from 'react';
import {Link} from 'react-router-dom';

import Green from '../images/Blog/environment.jpg';
import Tackling from '../images/Blog/tackling.png'
import Presenting from '../images/Blog/Presenting.jpg'
import Driving from '../images/Blog/driving.jpg'
import IC6Blog from '../images/ic6_windscreen.jpg';
import Future from '../images/Blog/future.jpg';


function BlogList() {
    return (
        <section className="z-20 relative">
            <div className="bg-gray-900 bg-opacity-80 relative max-w mx-auto px-4 sm:px-6" data-aos="fade-in"
                 data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                <div className="relative rounded-lg w-full sm:w-11/12 lg:w-5/6 mx-auto px-4 sm:px-6" data-aos="fade-in"
                     data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                    <div className="pt-20 pb-12 md:pt-8 md:pb-16">

                        {/*  Page header */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <h1 className="h2 mb-4 text-gray-100">IN THE NEWS</h1>
                        </div>

                        {/*  Featured article */}
                        <div className="pb-12 md:pb-20">
                            <article
                                className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
                                <Link to="/linkedinIC6" className="relative block group" data-aos="fade-right"
                                      data-aos-delay="200">
                                    <div
                                        className="absolute inset-0 bg-gray-900 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                                        aria-hidden="true"></div>
                                    <figure
                                        className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                                        <img
                                            className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                            src={Green} width="540" height="303" alt="Blog post 01"/>
                                    </figure>
                                </Link>
                                <div data-aos="fade-left" data-aos-delay="200">
                                    <header>
                                        <h3 className="h3 text-2xl lg:text-3xl mb-2">
                                            <Link to="/linkedinIC6" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out">
                                                Driving Green: How Telematics, Including IC6, Aid in Reducing
                                                Environmental Impact.</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">Environmental sustainability is a growing
                                        focus in the telematics industry, with solutions aimed at reducing carbon
                                        footprints and promoting eco-friendly driving practices. The IC6 device from AGM
                                        Technologies plays a pivotal role in this trend. While the industry emphasises
                                        real-time emissions tracking, the IC6 provides valuable data for post-journey
                                        analysis, supporting environmental goals in a different yet effective way...</p>
                                    <footer className="flex items-center mt-4">
                                        <div>
                                            <span className="text-gray-300">20th February 2024</span>
                                        </div>
                                    </footer>
                                </div>
                            </article>
                        </div>

                        {/*  Articles list */}
                        <div className="max-w-sm mx-auto md:max-w-none">

                            {/*  Section title */}
                            <h4 className="h4 pb-6 mb-10 border-b border-gray-200 text-gray-100"
                                data-aos="fade-up">Latest articles</h4>

                            {/*  Articles container */}
                            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

                                {/*  0 article */}
                                <article className="flex flex-col h-full" data-aos="fade-up">
                                    <header>
                                        <Link to="/linkedinIC6" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={Green} width="352" height="198" alt="News 01"/>
                                            </figure>
                                        </Link>
                                        <div className="mb-3">
                                        </div>
                                        <h3 className="h4 mb-2">
                                            <Link to="/linkedinIC6" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">Personalised
                                                Motor Insurance with UBI: The Role of IC6 in Revolutionising
                                                Premiums</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">The motor insurance industry is undergoing
                                        a significant transformation...</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">20th February 2024</span>
                                        </div>
                                    </footer>
                                </article>

                                {/*  1st article */}
                                <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                                    <header>
                                        <Link to="/itcVegas" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={Future} width="352" height="198" alt="News 01"/>
                                            </figure>
                                        </Link>
                                        <div className="mb-3">
                                        </div>
                                        <h3 className="h4 mb-2">
                                            <Link to="/itcVegas" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">The
                                                Future of Telematics: AI, Big Data, and the Advanced Features of IC6 and
                                                IC7</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">The integration of AI and Big Data in
                                        telematics is shaping the future of the industry, offering profound advancements
                                        in vehicle safety and efficiency...</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">6th February 2024</span>
                                        </div>
                                    </footer>
                                </article>

                                {/*  2nd article */}
                                <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                                    <header>
                                        <Link to="/diaAMS" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={Tackling} width="352" height="198" alt="News 02"/>
                                            </figure>
                                        </Link>
                                        <div className="mb-3">
                                        </div>
                                        <h3 className="h4 mb-2">
                                            <Link to="/diaAMS" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">ITC
                                                Europe Special: tackling rising car insurance premiums with
                                                telematics</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">Written by Roger Peverelli and Reggy de
                                        Feniks - Founders The DIA Community</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">29th January, 2024</span>
                                        </div>
                                    </footer>
                                </article>

                                {/*  3rd article */}
                                <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="300">
                                    <header>
                                        <Link to="/tourLife" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={Presenting} width="352" height="198" alt="News 03"/>
                                            </figure>
                                        </Link>
                                        <h3 className="h4 mb-2">
                                            <Link to="/tourLife" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">ITC
                                                DIA Europe Munich 2023 Show & Tell</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">If you didn't manage to make it to our
                                        presentation all about the IC7 at The ITC DIA Europe Community in Munich last
                                        month you can watch it here!</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">22th November 2023</span>
                                        </div>
                                    </footer>
                                </article>

                                {/*  4th article */}
                                <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400">
                                    <header>
                                        <Link to="/marathon" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={Driving} width="352" height="198" alt="News 04"/>
                                            </figure>
                                        </Link>
                                        <h3 className="h4 mb-2">
                                            <Link to="/marathon" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">5
                                                Compelling Reasons Why Your Business Needs the IC7 Telematics
                                                Device</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">In today's fast-paced business world,
                                        keeping a close eye your customers driving behaviour or your fleet's performance
                                        is crucial for maximising productivity and minimising costs...</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">21nd April 2023</span>
                                        </div>
                                    </footer>
                                </article>

                                {/*  5th article */}
                                <article className="flex flex-col h-full" data-aos="fade-up">
                                    <header>
                                        <Link to="/sti" className="block mb-6">
                                            <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                                                <img
                                                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                                                    src={IC6Blog} width="352" height="198" alt="News 01"/>
                                            </figure>
                                        </Link>
                                        <div className="mb-3">
                                        </div>
                                        <h3 className="h4 mb-2">
                                            <Link to="/sti" target="_blank" rel="noreferrer noopener"
                                                  className="text-gray-100 hover:text-gray-500 transition duration-150 ease-in-out">Collision
                                                Detection</Link>
                                        </h3>
                                    </header>
                                    <p className="text-lg text-gray-100 grow">Interested in low speed collision
                                        detection? Read below...</p>
                                    <footer className="flex items-center mt-4">
                                        <div className="font-medium">
                                            <span className="text-gray-300">31st March 2023</span>
                                        </div>
                                    </footer>
                                </article>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogList;