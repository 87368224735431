import React from 'react';
import useWindowDimensions from "../utils/GetScreenDimensions";


function LandingVideo() {
    const { height, width } = useWindowDimensions();

    return (
        <div className="w-full h-full">
            <div className="fixed inset-0 z-0 block">
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{height: "100%", width: "100%", objectFit: "cover"}}
                >
                    {height/width <= 1.4 ?
                        <source src="https://agmtwebsitevideo.s3.eu-west-1.amazonaws.com/LandingVideo.mp4"
                                type="video/mp4"/>
                        :
                        <source src="https://agmtwebsitevideo.s3.eu-west-1.amazonaws.com/Landing+Phone.mp4"
                                type="video/mp4"/>
                    }
                </video>
                <div className="absolute inset-0 bg-gray-900 opacity-30 z-0" aria-hidden="true"/>
            </div>
        </div>
    );
}

export default LandingVideo;
