import React from 'react';
import Devices from "../images/DSC02672 Large.png";

function Stats() {

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-6 md:py-10 border-t border-gray-800">

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-4 md:pb-0">
                        <h1 className="h3 mb-4 text-gray-100" data-aos="fade-up">ENVIRONMENTAL COMMITMENT</h1>
                        <p className="text-xl text-gray-200 pb-4" data-aos="fade-up">We are committed to playing an
                            active role in promoting solutions that do not have a harmful effect on the environment.
                            AGM Technologies have established an environmental process where we provide a service for
                            any expired
                            devices to be refurbished and reused or recycled.</p>
                        <figure className="relative h-100 overflow-hidden">
                            <div className="relative">
                                <img className="object-contain inline-block" src={Devices}
                                     alt="fireSpot" width="50%"/>
                            </div>
                        </figure>
                        <p className="text-xl text-gray-200 pb-2" data-aos="fade-up"><b>The components in our existing
                            solutions are 99% reusable.</b></p>
                    </div>


                </div>
            </div>
        </section>
    );
}

export default Stats;