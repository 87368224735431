import React from 'react';
import CookieConsent from "react-cookie-consent";


function HeroHome() {

    return (
        <section className="w-full h-screen z-20">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">

                {/* Hero content */}
                <div className="relative pt-16 pb-10 md:pt-40 md:pb-16">

                    {/* Section header */}
                    <CookieConsent
                        enableDeclineButton
                        style={{background: "rgba(231,228,228,0.51)", color: "#ffffff"}}
                        buttonStyle={{background: "#ffffff", color: "black", border: 2}}
                        contentStyle={{color: "#ffffff"}}
                        declineButtonStyle={{}}

                    >This website uses cookies to enhance the user experience.
                    </CookieConsent>
                    <div className="z-20 max-w-5xl mx-auto pb-12 md:pb-16 relative top-52">
                        <div>
                            <img className="object-contain inline-block" data-aos="fade-down" data-aos-duration="1500"
                                 src={require('../images/Logo/agmLogoWhite2.png').default} alt="fireSpot"/>
                            <div className="sm:text-3xl text-md text-bold text-center mb-4 relative text-white" data-aos="fade-up" data-aos-delay="500"
                                 data-aos-duration="2000">A Global Telematics and IoT Provider
                            </div>
                        </div>
                    </div>
                    <div className="z-30 relative">
                        <div className="absolute inset-x-0 top-6 right-15">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroHome;