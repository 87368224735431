import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PrivacyPolicyAndroidPartial() {
    return (
        <section className="relative">

            {/* Hero content */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="pt-32 pb-12 md:pt-40 md:pb-2">
                    <div className="max-w-5xl mx-auto text-gray-800 text-center md:text-left pb-2">
                        <h1 className="text-4xl font-bold mb-8">Privacy Policy for ICx Connect DT</h1>
                        <p><strong>Effective Date:</strong> 28/05/2024</p>

                        <p><strong>1. Introduction</strong></p>
                        <p>Welcome to ICx Connect DT, an application developed by AGM Technologies Limited. Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our mobile application, ICx Connect DT. We are committed to protecting your personal data and complying with the General Data Protection Regulation (GDPR).</p>

                        <p><strong>2. Information We Collect</strong></p>

                        <p><strong>a. Personal Information</strong></p>
                        <p>Email Address: We collect your email address when you register or communicate with us through the app.</p>

                        <p><strong>b. Usage Data</strong></p>
                        <p>We collect data on how you interact with the app, including features used, time spent on the app, and other related data to improve user experience and app functionality.</p>

                        <p><strong>c. Location Data</strong></p>
                        <p>Tracking in the Background: Our app collects location data while running in the background. This is necessary to enable real-time tracking of our bespoke Bluetooth device and to provide location-based services.</p>
                        <p>Bluetooth Device Search: We use your location data to search for and connect to our bespoke Bluetooth devices, ensuring accurate device location and connection performance.</p>

                        <p><strong>3. How We Use Your Information</strong></p>
                        <p>We use the collected information for the following purposes:</p>
                        <p>To Provide and Maintain Our Services: Including tracking the location of our Bluetooth devices and ensuring their accurate connection and functionality.</p>
                        <p>To Communicate with You: Sending you updates, notifications, and other information related to the app.</p>
                        <p>To Improve Our Services: Analyzing usage patterns to enhance app performance and user experience.</p>
                        <p>For Security and Compliance: Ensuring the security of your data and compliance with legal obligations.</p>

                        <p><strong>4. Legal Basis for Processing Personal Data</strong></p>
                        <p>Under GDPR, we process your personal data based on the following legal grounds:</p>
                        <p>Consent: When you have given us explicit consent to process your personal data for specific purposes.</p>
                        <p>Contractual Necessity: When processing is necessary to perform a contract with you or to take steps at your request before entering into a contract.</p>
                        <p>Legal Obligation: When processing is necessary for compliance with a legal obligation to which we are subject.</p>
                        <p>Legitimate Interests: When processing is necessary for our legitimate interests, provided these interests are not overridden by your fundamental rights and freedoms.</p>

                        <p><strong>5. Sharing Your Information</strong></p>
                        <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties except as described in this Privacy Policy. We may share your information with:</p>
                        <p>Service Providers: Trusted third-party service providers who assist us in operating our app, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>
                        <p>Legal Obligations: When required by law or in response to valid requests by public authorities.</p>

                        <p><strong>6. Data Security</strong></p>
                        <p>We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or method of electronic storage is completely secure.</p>

                        <p><strong>7. International Data Transfers</strong></p>
                        <p>Your information, including personal data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. We will take all necessary measures to ensure that your data is treated securely and in accordance with this Privacy Policy and GDPR.</p>

                        <p><strong>8. Your Rights Under GDPR</strong></p>
                        <p>You have the following rights concerning your data:</p>
                        <p>Access: You can request access to the personal data we hold about you.</p>
                        <p>Correction: You can request corrections to any inaccurate or incomplete data.</p>
                        <p>Deletion: You can request the deletion of your data, subject to certain legal obligations.</p>
                        <p>Restriction: You can request that we restrict the processing of your data under certain circumstances.</p>
                        <p>Portability: You can request the transfer of your data to another entity.</p>
                        <p>Objection: You can object to the processing of your data based on our legitimate interests or for direct marketing purposes.</p>
                        <p>Withdraw Consent: You can withdraw your consent at any time where we are relying on consent to process your personal data.</p>
                        <p>To exercise any of these rights, please contact us at: hello@agmtechnologies.co.uk</p>

                        <p><strong>9. Third-Party Links</strong></p>
                        <p>Our app may contain links to other sites. We are not responsible for the privacy practices or the content of such websites. We encourage you to read the privacy policies of each website that collects your personal information.</p>

                        <p><strong>10. Changes to This Privacy Policy</strong></p>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top. You are advised to review this Privacy Policy periodically for any changes.</p>

                        <p><strong>11. Contact Us</strong></p>
                        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                        <p>AGM Technologies Limited</p>
                        <p>Regency House, Alexandre Parade, Weston-super-Mare, BS23 1QZ</p>
                        <p>Email: hello@agmtechnologies.co.uk</p>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default PrivacyPolicyAndroidPartial;