import React from 'react';

import UBI from '../images/UBI3.png';
import animationData from '../lottie/lf30_editor_npjvn8qg.json';
import Lottie from 'react-lottie';
import bluetoothData from "../lottie/867-bluetooth.json";


function OurServices() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionBluetooth = {
        loop: true,
        autoplay: true,
        animationData: bluetoothData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <section>
            <div className="z-20 bg-gray-900 bg-opacity-80 relative max-w mx-auto px-2 sm:px-6" data-aos="fade-in"
                 data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                <div className="relative sm:w-5/6 mx-auto px-4 sm:px-6" data-aos="fade-in" data-aos-duration="1500"
                     data-aos-anchor-placement="top-bottom">
                    <div className="py-12 md:py-10  border-b border-gray-100">

                        {/* Section header */}
                        <div className="max-w-6xl mx-auto text-center pb-12 md:pb-12" data-aos="fade-up">
                            <p className="h2 text-gray-100">SOLUTIONS</p>
                            <p className="h4 text-gray-400 mt-4">A platform of devices and software solutions to increase
                                safety and reduce costs.</p>
                        </div>


                        {/* Articles */}
                        <div
                            className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 md:gap-6 lg:gap-6 items-start md:max-w-none">

                            {/* 1st article */}
                            <article className="flex flex-col h-full border border-gray-700 rounded-lg"
                                     data-aos="fade-up">

                                <a className="block" href="/telematics">
                                    <figure
                                        className="relative h-0 pb-9/16 overflow-hidden border border-gray-900 rounded-lg bg-gray-900">
                                        <div className="relative">
                                            <div
                                                className="transform rotate-90 lg:left-28 lg:top-6 left-14 -top-5 absolute">
                                                <Lottie options={defaultOptions} height={200} width={200}/>
                                            </div>
                                            <div className="absolute -top-12 -right-12">
                                                <Lottie options={defaultOptionBluetooth} height={200}
                                                        width={200}/>
                                            </div>
                                            <img className="w-full object-cover -translate-y-3"
                                                 src={require('../images/Dash.png').default} width="516"
                                                 height="387" alt="SolutionsTabs 01"/>
                                        </div>
                                    </figure>
                                </a>
                                <div className="flex flex-col justify-between h-full p-6 bg-gray-800">
                                    <div>
                                        <h3 className="h4 mb-2 text-center">
                                            <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                               href="/telematics">Telematics Solutions</a>
                                        </h3>
                                        <p className="text-lg text-gray-100 mb-4 text-center">Our devices are
                                            self-install and
                                            feature high frequency GPS and 3-axis accelerometer with optional
                                            enhancements
                                            to produce bespoke solutions. All of our products are fully white-labelled
                                            and configurable to your specification.</p>
                                        <p className="text-lg text-gray-100 mb-2 text-center">Our dedicated hardware
                                            team are
                                            constantly innovating, utilising the latest technology to enhance our
                                            product range.</p>
                                    </div>
                                    <div className="flex justify-center">
                                        <a className="btn-sm text-white bg-blue-600 hover:bg-purple-700 mt-2"
                                           href="/telematics">
                                            <span className="text-lg">Learn more</span>
                                        </a>
                                    </div>
                                </div>


                            </article>

                            {/* 2nd article */}
                            <article className="flex flex-col h-full border border-gray-700 rounded-lg"
                                     data-aos="fade-up" data-aos-delay="200">
                                <a className="block" href="/platform-solutions">
                                    <figure
                                        className="relative h-0 pb-9/16 overflow-hidden border border-gray-900 rounded-lg">
                                        <img
                                            className="absolute inset-0 w-full h-full object-fit transform scale-100 transition duration-700 ease-out"
                                            src={UBI} alt="News 05"/>
                                    </figure>
                                </a>
                                <div className="flex flex-col justify-between h-full p-6 bg-gray-800">
                                    <div>
                                        <h3 className="h4 mb-2 text-center">
                                            <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                               href="/platform-solutions">Software Solutions</a>
                                        </h3>
                                        <p className="text-lg text-gray-100 mb-2 text-center">
                                            Fully flexible, our software capabilities adapt to suit your needs. Whether
                                            it be a full stack telematics requirement or ingesting our comprehensive
                                            data streams directly into your platform via APIs and our SDK.
                                        </p>
                                        <p className="text-lg text-gray-100 pt-3 text-center">
                                            Our end-to-end platform is entirely customizable, with user-friendly mobile
                                            applications and a detailed dashboard for broader evaluation.
                                        </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <a className="btn-sm text-white bg-blue-600 hover:bg-purple-700"
                                           href="/platform-solutions">
                                            <span className="text-lg">Learn more</span>
                                        </a>
                                    </div>
                                </div>


                            </article>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurServices;
