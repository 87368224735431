import React from 'react';

function Team() {
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-6 md:py-10 border-t border-gray-800">

                    {/* Section header */}
                    <div className="max-w-4xl mx-auto text-center pb-16 md:pb-16">
                        <h2 className="h3 mb-4 text-gray-100">LEADERSHIP TEAM</h2>
                    </div>

                    {/* Team members */}
                    <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

                        {/* 1st member */}
                        <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 py-4 sm:py-8 sm:px-3" data-aos="fade-up"
                             data-aos-anchor="[data-aos-id-team]">
                            <div className="grid grid-cols-2 ">
                                <div className="flex flex-wrap justify-center">
                                    <img className="rounded-full mb-4"
                                         src={require('../images/TeamProfiles/Kerry.jpg').default}
                                         width="140" height="140" alt="Team member 01"/>
                                </div>
                                <div className="flex flex-wrap justify-center  basis-full flex-col">
                                    <h4 className="text-xl font-medium mb-1 text-left text-gray-100">Kerry Michael
                                        MBE</h4>
                                    <div className="mb-1 text-left text-gray-300">Chairman</div>
                                </div>
                            </div>
                            <div className="text-gray-200 text-left mb-1 px-6">Before founding AGM Technologies, Kerry
                                established The Regency Group, which grew to become Britain’s largest extended-warranty
                                insurance company. The RAC appointed Kerry Commercial Director, where he was an integral
                                part of the team responsible for the business’s turnaround in the 2010s. It was here,
                                Kerry noticed the growing importance and influence that Telematics was to have on the
                                insurance industry and he moved to establish AGM Technologies and begin work on
                                analytical solutions to cater for the mass market.
                            </div>

                        </div>

                        {/* 2nd member */}

                        {/* 3rd member */}
                        <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 py-4 sm:py-8 sm:px-3" data-aos="fade-up"
                             data-aos-delay="200" data-aos-anchor="[data-aos-id-team]">
                            <div className="grid grid-cols-2 ">
                                <div className="flex flex-wrap justify-center">
                                    <img className="rounded-full mb-4"
                                         src={require('../images/TeamProfiles/George.jpg').default}
                                         width="140" height="140" alt="Team member 01"/>
                                </div>
                                <div className="flex flex-wrap justify-center  basis-full flex-col">
                                    <h4 className="text-xl font-medium mb-1 text-left text-gray-100">George Michael</h4>
                                    <div className="mb-1 text-left text-gray-300">Chief Executive Officer</div>
                                </div>
                            </div>
                            <div className="text-gray-200 text-left mb-1 px-6">George’s priority is on driving
                                profitability whilst ensuring the quality delivery of our products. With nearly 10 years
                                of telematics experience, George has quickly developed his technical understanding to
                                help translate the technological capability of our solutions to meet customer
                                requirements. By streamlining our logistical operations through enhancing supply chain
                                relationships and improving efficiency, George has implemented the ideal structure for
                                AGMT to grow. He has supervised the technical redesign of our products to mitigate the
                                impact of a disrupted supply chain, which will now see AGMT move forward with dual
                                supply for products suite.
                            </div>

                        </div>

                        {/* 3rd member */}
                        <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 py-4 sm:py-8 sm:px-3" data-aos="fade-up"
                             data-aos-delay="200" data-aos-anchor="[data-aos-id-team]">
                            <div className="grid grid-cols-2 ">
                                <div className="flex flex-wrap justify-center">
                                    <img className="rounded-full mb-4"
                                         src={require('../images/TeamProfiles/Joe.jpg').default}
                                         width="140" height="140" alt="Team member 01"/>
                                </div>
                                <div className="flex flex-wrap justify-center  basis-full flex-col">
                                    <h4 className="text-xl font-medium mb-1 text-left text-gray-100">Joe Walford</h4>
                                    <div className="mb-1 text-left text-gray-300">Head of Cloud Development</div>
                                </div>
                            </div>
                            <div className="text-gray-200 text-left mb-1 px-6"> Leveraging his exceptional development
                                skills and analytical mindset, Joe expertly overseas the businesses’ technical
                                requirements, spanning the full range of our company’s software domain. His role
                                encompasses driving innovative product development and the strategic oversight of our
                                software services. Since joining the team, Joe has significantly enhanced our software
                                capabilities and has been pivotal in the launch of our IC7, dashboard and low-speed
                                crash detection.
                            </div>

                        </div>

                        {/* 4th member */}
                        <div className="sm:w-1/2 md:w-1/2 lg:w-1/2 py-2 sm:py-8 sm:px-3" data-aos="fade-up"
                             data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
                            <div className="grid grid-cols-2 ">
                                <div className="flex flex-wrap justify-center">
                                    <img className="rounded-full mb-4"
                                         src={require('../images/TeamProfiles/Wayne.jpg').default}
                                         width="140" height="140" alt="Team member 01"/>
                                </div>
                                <div className="flex flex-wrap justify-center  basis-full flex-col">
                                    <h4 className="text-xl font-medium mb-1 text-left text-gray-100">Wayne Gilbert</h4>
                                    <div className="mb-1 text-left text-gray-300">Former Co-Founder</div>
                                </div>
                            </div>
                            <div className="text-gray-200 text-left mb-1 px-6">Recently passed, Wayne’s technical
                                expertise was at the forefront of all product advancements at AGMT. As a former Director
                                at Celestica and TES Electronic Solutions, where he developed emerging opportunities in
                                technology and wireless and optical networks, Wayne was a telematics industry expert.
                                Experience managing intellectual property as CTO for Masternaut and Risk Technologies
                                enabled Wayne to help AGMT secure vital development patents in both Europe and the US.
                                Thanks to his guidance, Wayne left AGMT in a perfect position to become an established
                                technology solution provider. Wayne's impact on the industry was monumental and he will
                                be missed by anyone that had the pleasure to cross paths with him.
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </section>
    );
}

export default Team;